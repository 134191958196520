import LoggedInLayout from "../../layouts/loggedInLayout";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import {
  BsTrashFill,
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";

import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  FieldPath,
  orderBy,
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";
import moment from 'moment';

export default function VideoPage(props) {
  const [user, loading, error] = useAuthState(auth);
  const [video, setVideo] = useState([]);
  const [ori, setOri] = useState([]);

  window.onbeforeunload = function () {
    return 'Are you sure you want to leave?';
  };

  useEffect(() => {
    const getDbData = async (uid) => {
      const q = query(
        collection(db, `db_uni/${uid}/video`),
        where("order", "!=", -1),
        orderBy("order")
      );
      let data = await getDocs(q);
      setVideo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setOri(video);
    };

    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        getDbData(user.uid);
      }
    });

    return () => {
      setVideo([]);
      setOri([]);
    };
  }, []);

  function checkAddField() {
    let vidId = $("#usrInput").val();
    if (vidId == "") {
      $("#addMsg").addClass("d-none");
    } else {
      $("#addMsg").removeClass("d-none");
    }
  }

  $(document).ready(function () {
    video.map((vid) => {
      $(`#${vid.order}-Desc`).val(vid.vidId);
    });
  });

  function save() {
    let descUsr = $("#usrInput").val();
    if (descUsr == "") {
      // do ntg
    } else {
      notify(
        "warning",
        'Please click "Add" or clear bottom field before save.',
        "Incomplete Input"
      );
      return;
    }

    let latestList = getUpdateList();
    setVideo(latestList);

    window.preloader(true);
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateVideo",
      data: {
        uid: user.uid,
        videos: latestList,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  function revertChg() {
    let confirmChg = window.confirm("Are you sure to discard change made?");
    let a;
    confirmChg ? setVideo(ori) : (a = 1);
  }

  function swapItem(order) {
    let arr = getUpdateList();
    let total = arr.length;

    //swap with top
    if (order == 0) {
      notify("warning", "Cannot Swap Toppest Item!", "Swap Failed");
    } else {
      let temp = arr[order];
      arr[order] = {
        videoURL: arr[order - 1].videoURL,
        order: order,
        id: `video_${order}`,
      };
      arr[order - 1] = {
        videoURL: temp.videoURL,
        order: order - 1,
        id: `video_${order - 1}`,
      };
    }
    setVideo(arr);
  }

  function deleteItem(order) {
    let arr = getUpdateList();
    let total = arr.length;

    if (order + 1 == total) {
      arr.pop();
    } else if (order >= 0) {
      for (var i = order; i < total; i++) {
        var ab = i;
        arr[i].order = i - 1;
        arr[i].id = `video_${i - 1}`;
        arr[i].vidId = arr[ab].vidId;
      }
    }
    arr.splice(order, 1);
    setVideo(arr);
  }

  function addItem() {
    let arr = getUpdateList();
    let vidId = $("#usrInput").val();
    let order = arr.length;
    let id = `video_${order}`;

    const addedArr = [{ videoURL: vidId, order: order, id: id, createdOn: moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString() }];
    let newArr = arr.concat(addedArr);
    setVideo(newArr);
    $("#usrInput").focus();
    $("#usrInput").val("");

  }

  function getUpdateList() {
    let total = video.length;
    let arr = [];
    setVideo([]);

    for (var i = 0; i < total; i++) {
      let vidId = $(`#${i}-Vid`).html();
      let co = $(`#${i}-CO`).val();
      arr.push({
        videoURL: vidId,
        order: i,
        createdOn: co,
        id: `video_${i}`,
      });

      console.log(arr);

      $(`#${i}-Vid`).val(vidId);
    }
    return arr;
  }

  return (
    <LoggedInLayout>
      <div className="contentContainer">
        <div className="contentTitle position-relative">
          <span>Edit Video</span>
        </div>

        <form className="contentBody">
          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">Video</th>
                  <th scope="col" style={{ width: "5%" }}>
                    Order
                  </th>
                  <th scope="col" style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {video.map((vid) => {
                  return (
                    <tr key={vid.id}>
                      <input type="hidden" id={vid.order + '-CO'} value={vid.createdOn} readOnly></input>
                      <td>
                        <span id={vid.order + '-Vid'}>
                          {vid.videoURL}
                        </span>
                      </td>
                      <td className="text-center align-middle">
                        {vid.order == 0 ? (
                          <button
                            type="button"
                            className="align-center btn-sm btn-secondary"
                            disabled
                          >
                            <BsFillArrowUpCircleFill className="mb-1" />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="align-center btn-sm btn-primary"
                            onClick={() => swapItem(vid.order, "up")}
                          >
                            <BsFillArrowUpCircleFill className="mb-1" />
                          </button>
                        )}
                      </td>
                      <td className="text-center align-middle">
                        <button
                          type="button"
                          className="btn-sm btn-danger"
                          onClick={() => {
                            deleteItem(vid.order);
                          }}
                        >
                          <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                <tr className="bg-info">
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id="usrInput"
                      placeholder="Add Youtube Url..."
                      onKeyUp={() => checkAddField()}
                    ></input>
                  </td>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      className="btn-sm btn-primary mt-1 align-center"
                      onClick={() => {
                        addItem();
                      }}
                    >
                      Add
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="text-danger d-none" id="addMsg">
              <i>Please click "Add" to add items.</i>
            </span>
          </div>
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-success ms-3"
              id="submit"
              onClick={() => save()}
            >
              <IoIosSave /> Save
            </button>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
