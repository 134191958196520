import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/profile.css";
import Modal from "../../components/modal-container";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import MultipleImgComponent from "../../components/uploadImages/uploadMultipleImages";

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
  FieldPath,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function HostelPage(props) {
  const [user] = useAuthState(auth);

  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  const [hostels, setHostels] = useState([]);
  const [curHostel, setCurHostel] = useState({});

  const [campuses, setCampuses] = useState([]);
  const [selCampuses, setSelCampuses] = useState([]);
  const [selDefCampuses, setSelDefCampuses] = useState([]);

  //multipleImg
  const [userId, setUserId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  //------

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      let preloader_counter = 0;
      if (user) {
        setUserId(user.uid);
        const q = query(
          collection(db, `db_uni/${user.uid}/hostel`),
          where("sort", "!=", "-1")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setHostels([]);
          querySnapshot.forEach((doc) => {
            setHostels((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });
        const getDbData = async () => {
          const q = query(
            collection(db, `db_uni/${user.uid}/hostel`),
            where("sort", "!=", "-1"),
            orderBy("sort")
          );
          const data = await getDocs(q);
          setHostels(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          preloader_counter++;
          if (preloader_counter >= 1) {
            window.preloader(false);
          }
        };
        const getCampusData = async () => {
          const q = query(
            collection(db, `db_uni/${user.uid}/campus`),
            where("location", "not-in", ["", "placeholder"])
          );
          const data = await getDocs(q);
          setCampuses(
            data.docs.map((doc) => ({
              value: doc.id,
              label: doc.data().location,
            }))
          );
          preloader_counter++;
          if (preloader_counter >= 1) {
            window.preloader(false);
          }
        };

        window.preloader(true);
        getDbData();
        getCampusData();

        return () => {
          setUserId("");
          unsub();
        };
      }
    });
  }, []);

  const handleImgChange = (images) =>{ //multipleImg
    console.log('parent: ');
    console.log(images);
  }

  const chgCampuses = (e) => {
    setSelCampuses(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const deleteItem = async (tcurId) => {
    //prompt delete or not.
    const getUIntro = await deleteDoc(
      doc(db, `db_uni/${user.uid}/hostel`, tcurId)
    );
    notify("success", `"${curHostel.name}" is Deleted!`, "Deleted Successfully");
    setShowModal(!showModal);
  };

  const popupModal = (id, uid) => {
    initHostel();
    if (id == "") {
      setCurHostel({});
      setAction("New");
    }
    else if (id == "Delete") {
      var curH = hostels.find((item) => item.id == uid);
      setCurHostel(curH);
      setAction("Delete");

    }
    else {
      setAction("Edit");
      getHostelDetail(id);
    }
    setShowModal(true);
  };

  const initHostel = () =>{
    setSelCampuses([]);
    setSelDefCampuses([]);
    setCurHostel({});
  }

  const getHostelDetail = (id) => {
    var curH = hostels.find((item) => item.id == id);
    setCurHostel(curH);

    for (var key in curH.campuses) {
      const item = campuses.find((item) => item.value == curH.campuses[key]);
      setSelDefCampuses((oldArr) => [...oldArr, item]);
    }
    setSelCampuses(curH.campuses);
  };

  const beforeSave = (images) =>{// beforesave
    if(images != null){
      save(curHostel.id, images);
    }
  }

  const save = async (curId, inputImgs) => {
    setSubmitted(true);
    window.preloader(true);
    curId == "New" ? (curId = "") : (curId = curId);

    let name = $("#name").val();
    let name_cn = $("#name_cn").val();
    let type = $("#type").val();
    let type_cn = $("#type_cn").val();
    let location = $("#location").val();
    let facilities = $("#facilities").val();
    let facilities_cn = $("#facilities_cn").val();
    let cost = $("#cost").val();
    let costPeriod = $("#costPeriod").val();
    let availability = $("#availability").val();
    let availability_cn = $("#availability_cn").val();
    let publish = $("#publish").prop("checked");
    let sort = $("#sort").val();

    console.log('save: ');
    console.log(inputImgs);

    let campuses = selCampuses;
    if(selCampuses!=null){
      if (selCampuses.length == 0 || (action=="Edit" && curHostel.campuses == 0)) {
          notify("warning", "Please select at least 1 campuses.", "Missing Field");
          window.preloader(false);
          return;
      }
    }
    else{
      notify("warning", "Please select at least 1 campuses.", "Missing Field");
      window.preloader(false);
      return;
    }

    //Checking may needed
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyHostel",
      data: {
        action: action,
        uid: user.uid,
        curId: curId,
        name: name,
        name_cn: name_cn,
        type: type,
        type_cn: type_cn,
        location: location,
        campuses: campuses,
        facilities: facilities,
        facilities_cn: facilities_cn,
        cost: cost,
        costPeriod: costPeriod,
        availability: availability,
        availability_cn: availability_cn,
        sort: sort,
        images: inputImgs,
        publish: publish,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "New Campus is created!", "Created Successfully");
          alert(`New campus is added Successfully! Kindly check after 5 mins if change is not visible.`);
        } else if (msg === "2") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          action == "New"
            ? notify("error", "Creation Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
        setShowModal(!showModal);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          //save(curHostel.id);
          setSubmitted(true);
        }}
        onDelete={() => {
          deleteItem(curHostel.id);
        }}
        itemName={curHostel.name}
        action={action}
        title="Hostel"
      >
        <input
          type="hidden"
          id="sort"
          value={action == "New" ? hostels.length : curHostel.sort}
        ></input>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Hostel Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            defaultValue={curHostel.name}
            placeholder="Example: My Place Apartment"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="hostel_cn" className="my-FormLabel">
            Hostel Name / 宿舍名称
          </label>
          <input
            type="text"
            className="form-control"
            id="name_cn"
            defaultValue={curHostel.name_cn}
            placeholder="例：My Place 公寓"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="type" className="my-FormLabel">
            Accomodation Type
          </label>
          <input
            type="text"
            className="form-control"
            id="type"
            defaultValue={curHostel.name}
            placeholder="e.g. single room, double room etc."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="type_cn" className="my-FormLabel">
            Accomodation Type (CN) / 宿舍类型
          </label>
          <input
            type="text"
            className="form-control"
            id="type_cn"
            defaultValue={curHostel.type_cn}
            placeholder="例：单人房、双人房，等等"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="availability" className="my-FormLabel">
            Availability
          </label>
          <input
            type="text"
            className="form-control"
            id="availability"
            defaultValue={curHostel.availability}
            placeholder="e.g. accommodate up to 100 students."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="availability_cn" className="my-FormLabel">
            Availability (CN) / 名额
          </label>
          <input
            type="text"
            className="form-control"
            id="availability_cn"
            defaultValue={curHostel.availability_cn}
            placeholder="例：可容纳100位学生"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="campuses">Campus</label>
          <Select
            isMulti
            options={campuses}
            defaultValue={selDefCampuses}
            onChange={chgCampuses}
          />
        </div>
        <div className="form-group mt-1">
          <label htmlFor="location">Location</label>
          <select
            id="location"
            className="form-select"
            defaultValue={curHostel.location == "" ? "-1" : curHostel.location}
          >
            <option value="-1" disabled>
              Choose Hostel Location
            </option>
            <option value="withinCampus">Within Campus</option>
            <option value="nearCampus">Near Campus</option>
          </select>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="facilities" className="my-FormLabel">
            Facilities
          </label>
          <textarea
            className="form-control"
            rows="5"
            placeholder="(Not More than 10 Items) e.g. Air-cond, furniture, private bathroom/shared bathroom, laundry etc."
            id="facilities"
            defaultValue={curHostel.facilities}
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="facilities_cn" className="my-FormLabel">
            Facilities (CN) / 设备
          </label>
          <textarea
            className="form-control"
            rows="5"
            placeholder="(不超过10项)，例：冷气、家具、个别浴室／共用浴室、洗衣间等等。"
            id="facilities_cn"
            defaultValue={curHostel.facilities_cn}
          ></textarea>
        </div>

        <div className="form-group mt-1">
          <label htmlFor="cost" className="my-FormLabel">
            Charge
          </label>
          <div className="input-group">
            <div className="input-group-prepend w-1" style={{ width: "50px" }}>
              <input
                type="text"
                className="form-control"
                value="RM"
                disabled
              ></input>
            </div>
            <div className="input-group-prepend">
              <input
                type="number"
                className="form-control"
                id="cost"
                defaultValue={
                  curHostel.cost == null || curHostel.cost == ""
                    ? 0
                    : Number.parseInt(curHostel.cost)
                }
              ></input>
            </div>
            <select
              id="costPeriod"
              className="form-select"
              defaultValue={
                curHostel.costPeriod === "" ? "month" : curHostel.costPeriod
              }
            >
              <option value="month">Per Month</option>
              <option value="semester">Per Semester</option>
              <option value="year">Per Year</option>
            </select>
          </div>
        </div>

        <div className="form-group mt-1">
          <label htmlFor="cost" className="my-FormLabel">
            Preview Images
          </label>
          <div className="input-group">
            <MultipleImgComponent imgList={curHostel.images} uid={userId} pageName={"hostel"} onBeforeSubmit={(images)=>beforeSave(images)} onListHandler={(images)=>handleImgChange(images)} submitted={submitted} onInit={() => {setSubmitted(false);}}/>
          </div>
        </div>

        {/* Upload Image function */}

        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={curHostel.publish}
          ></input>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Hostel(s)</span>
        </div>

        <form className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addHostel"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Hostel
            </button>
          </div>

          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr className="text-center">
                  <th scope="col">Hostel Name</th>
                  <th scope="col">Hostel Name(CN)</th>
                  <th scope="col">Location</th>
                  <th scope="col">For Campus</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {hostels.length == 0 ? (
                  <tr>
                    <td colSpan="9">Empty</td>
                  </tr>
                ) : (
                  hostels.map((hostel) => {
                    return (
                      <tr key={hostel.sort} className="align-middle">
                        <td>{hostel.name}</td>
                        <td>{hostel.name_cn}</td>
                        <td>
                          {hostel.location == "withinCampus"
                            ? "In Campus"
                            : "Off Campus"}
                        </td>
                        <td>
                          {hostel.campuses.map((campusDoc, i) => {
                            var a = "";
                            {
                              campuses.map((cam) => {
                                if (cam.value === campusDoc) {
                                  if (i != 0) {
                                    a += `, `
                                  }
                                  a += `${cam.label}`;
                                }
                              });
                            }
                            return a;
                          })}
                        </td>
                        <td>{hostel.cost}</td>
                        <td>{hostel.publish ? "Active" : "Inactive"}</td>
                        <td>
                          <button
                            type="button"
                            className="btn-sm btn-primary me-1 mb-1"
                            onClick={() => {
                              popupModal(hostel.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal("Delete", hostel.id);
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
