import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export function notify(type, msg, title){
  let interval = 2000;
  switch (type) {
      case 'info':
        NotificationManager.info(msg, title, interval);
        break;
      case 'success':
        NotificationManager.success(msg, title, interval);
        break;
      case 'warning':
        NotificationManager.warning(msg, title, interval);
        break;
      case 'error':
        NotificationManager.error(msg, title, interval);
        break;
  }
}