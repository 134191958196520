import LoggedInLayout from "../layouts/loggedInLayout";
import { notify } from "../components/notify";

export default function DashboardPage(props) {
  return (
    <LoggedInLayout>
      <div className="contentContainer">
        Currently has no data to display.
      </div>
    </LoggedInLayout>
  );
}
