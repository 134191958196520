import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/profile.css";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import defaultImg from '../../assets/images/upload-placeholder.jpg';

import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  query
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function ProfilePage(props) {
  const [user] = useAuthState(auth);
  const [profile, setProfile] = useState([]);
  const [uniTypes, setUniTypes] = useState([]);

  useEffect(() => {
    async function getDbData(tuid) {
      window.scrollTo(0, 0);

      const getData = await getDoc(
        doc(db, `db_uni/${tuid}/information`, "profile")
      );
      if (getData.exists()) {
        const data = getData.data();
        setProfile(data);
        $("#insType").val(data.insType);
      }
    }

    const getUniTypeData = async () => {
      window.preloader(true);
      const q = query(
        collection(db, `db_shared_resources/db_uni/db_uni_UniType`)
      );
      let data = await getDocs(q);
      setUniTypes(
        data.docs.map((doc) => ({ value: doc.id, label: doc.data().name }))
      );
      window.preloader(false);
    };

    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        getUniTypeData(user.uid);
        getDbData(user.uid);
      }
    });

    function readURL(input, type) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $(`#${type}-preview`).attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    $("#schLogo").change(function () {
      readURL(this, "schLogo");
    });

    $("#schCover").change(function () {
      readURL(this, "schCover");
    });

    return () => {
      setProfile([]);
    };
  }, []);

  function save() {
    window.preloader(true);

    let file = document.getElementById("schLogo").files[0];
    let file2 = document.getElementById("schCover").files[0];

    if (profile.schLogo === "" || profile.schCover === "") {
      if (!file || !file2) {
        notify(
          "warning",
          `Necessary images is missing, please check and upload it.`,
          "Update Failed"
        );
        window.preloader(false);
        return;
      }
    }

    let insName = $("#insName").val();
    let insName_cn = $("#insName_cn").val();
    let insWebsite = $("#insWebsite").val();
    let insType = $("#insType").val();
    let insEstDate = $("#insEstDate").val();
    let insFounder = $("#insFounder").val();
    let insFounder_cn = $("#insFounder_cn").val();
    let insLeaderName = $("#insLeaderName").val();
    let insLeaderName_cn = $("#insLeaderName_cn").val();
    let insLocalStudNo = $("#insLocalStudNo").val();
    let insInterStudNo = $("#insInterStudNo").val();
    let insTeachingStaffNo = $("#insTeachingStaffNo").val();
    let insEmail = $("#insEmail").val();

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateProfile",
      data: {
        uid: user.uid,
        insName: insName,
        insName_cn: insName_cn,
        insFbUsername: "",
        insWebsite: insWebsite,
        insType: insType,
        insEstDate: insEstDate,
        insFounder: insFounder,
        insFounder_cn: insFounder_cn,
        insLeaderName: insLeaderName,
        insLeaderName_cn: insLeaderName_cn,
        insLocalStudNo: insLocalStudNo,
        insInterStudNo: insInterStudNo,
        insTeachingStaffNo: insTeachingStaffNo,
        insEmail: insEmail,
      },
      success: function (msg) {
        if (msg === "1") {
          uploadImages();

          window.preloader(false);
        } else if (msg == "2") {
          notify(
            "warning",
            "Profile is Not Updated on shared resources! Please contact admin",
            "Warning"
          );
        } else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  async function uploadImages() {
    let imgsName = ["schLogo", "schCover"];
    let imageLoop = 0;

    for (var i = 0; i < imgsName.length; i++) {
      let file = document.getElementById(imgsName[i]).files[0];

      if (file == null || file == "") {
      } else {
        const storageRef = ref(
          storage,
          `HighSchool_Universities/${user.uid}/profileImages/${imgsName[i]}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (err) => console.log(err),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const updateURL = async () => {
                const tDoc = doc(
                  db,
                  `db_uni/${user.uid}/information`,
                  `profile`
                );
                const updatedField = { [`${imgsName[imageLoop]}`]: url };

                const srDoc = doc(
                  db,
                  `db_shared_resources/db_uni/db_all_Uni`,
                  user.uid
                );

                if (imageLoop == 0) {
                  await updateDoc(srDoc, updatedField);
                }
                await updateDoc(tDoc, updatedField);
              };

              updateURL();
              imageLoop++;
            });
          }
        );
      }
    }

    notify("success", "Profile is uploaded successfully", "Profile Updated");
  }

  return (
    <LoggedInLayout>
      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Edit Profile</span>
          <button
            className="btn btn-warning position-absolute ms-12 previewBtn"
            onClick={() => alert("Coming Soon!")}
          >
            Preview
          </button>
        </div>

        <form className="contentBody">
          <h5 className="text-muted"><i>Edit institution's basic information and logo</i></h5>
          <div className="form-group">
            <label htmlFor="schLogo" className="form-label my-FormLabel">
              School Logo
            </label>
            <div className="input-group">
              <img
                id="schLogo-preview"
                className="schLogoPreview"
                src={
                  profile.schLogo !== ""
                    ? profile.schLogo
                    : defaultImg
                }
              />
              <span className="input-group-btn pd-0">
                <span className="btn btn-default btn-file">
                  <input type="file" className="form-control" id="schLogo" accept="image/*"></input>
                </span>
                <br />
                <small className="text-muted ms-3">
                  Upload 300x300 or larger for best resolution, transparent
                  background will be filled with white color
                </small>
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="schCover" className="form-label my-FormLabel">
              School Cover
            </label>
            <div className="input-group">
              <img
                id="schCover-preview"
                className="schCoverPreview"
                src={
                  profile.schCover !== ""
                    ? profile.schCover
                    : defaultImg
                }
              />
              <span className="input-group-btn">
                <span className="btn btn-default btn-file">
                  <input type="file" className="form-control" id="schCover" accept="image/*"></input>
                </span>
                <br />
                <small className="text-muted ms-3">
                  Upload 1280x720 or larger for best resolution, transparent
                  background will be filled with white color
                </small>
              </span>
            </div>
          </div>

          <div className="form-group mt-2">
            <label htmlFor="insName" className="my-FormLabel">
              Name of Institution
            </label>
            <input
              type="text"
              className="form-control"
              id="insName"
              defaultValue={profile.insName}
              placeholder="Institution Name"
            ></input>
            <small className="text-muted">
              Please indicate the English name in full, E.g. Tunku Abdul Rahman
              University College
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="insName_cn" className="my-FormLabel">
              Chinese Name of Institution / 学校中文名称
            </label>
            <input
              type="text"
              className="form-control"
              id="insName_cn"
              defaultValue={profile.insName_cn}
              placeholder="学校中文名称/ Chinese Institution Name"
            ></input>
            <small className="text-muted">
              请填写完整中文名称，例：国立暨南国际大学
            </small>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insWebsite" className="my-FormLabel">
              Website
            </label>
            <input
              type="text"
              className="form-control"
              id="insWebsite"
              defaultValue={profile.insWebsite}
              placeholder="E.g: https://www.example.com/"
            ></input>
          </div>

          <div className="form-group mt-1">
            <label htmlFor="insType" className="my-FormLabel">Type of Institution</label>
            <select
              id="insType"
              className="form-select"
            >
              <option value="" disabled>Select Institution Type</option>
              {uniTypes.map((type) => {
                return (
                  <option key={type.value} value={(type.value)}>
                    {type.label}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group mt-1">
            <label htmlFor="insEstDate" className="my-FormLabel">
              Year of Establishment
            </label>
            <input
              type="text"
              defaultValue={profile.insEstDate}
              className="form-control"
              placeholder="E.g: 1998"
              id="insEstDate"
            ></input>
            <small className="text-muted">
              Please indicate the institution’s year of establishment, E.g. 1988
            </small>
          </div>

          <div className="form-group mt-1">
            <label htmlFor="insFounder" className="my-FormLabel">
              English Name of Founding
              organization/Founder
            </label>
            <input
              type="text"
              className="form-control"
              id="insFounder"
              defaultValue={profile.insFounder}
              placeholder="E.g. Dong Jiao Zong
              Higher Education Centre (Non-profit) Bhd, Paramount Corporation
              Berhad"
            ></input>
            <small className="text-muted">
              Institution’s founding organization or
              organization owning the property rights, E.g. Dong Jiao Zong
              Higher Education Centre (Non-profit) Bhd, Paramount Corporation
              Berhad
            </small>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insFounder_cn" className="my-FormLabel">
              Chinese Name of Founding
              organization/Founder / 创办机构/创办人中文名称
            </label>
            <input
              type="text"
              className="form-control"
              id="insFounder_cn"
              placeholder="例：董教总教育中心（非营利）有限公司、Paramount Corporation Berhad"
              defaultValue={profile.insFounder_cn}
            ></input>
            <small className="text-muted">
              请填写学校的创办机构或产权机构，例：董教总教育中心（非营利）有限公司、Paramount
              Corporation Berhad
            </small>
          </div>

          <div className="form-group mt-1">
            <label htmlFor="insLeaderName" className="my-FormLabel">
              English Name of Leader (Designation)
            </label>
            <input
              type="text"
              className="form-control"
              id="insLeaderName"
              defaultValue={profile.insLeaderName}
              placeholder="E.g. Professor XXX (Principal), Dr XXX (Chief Executive Officer), Mr/Mdm XXX (Board Chairperson)"
            ></input>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insLeaderName_cn" className="my-FormLabel">
              Chinese Name of Leader (Designation) / 领导人中文名称（职称）
            </label>
            <input
              type="text"
              className="form-control"
              id="insLeaderName_cn"
              defaultValue={profile.insLeaderName_cn}
              placeholder="例：XXX教授（校长）、XXX博士（执行长）、XXX先生／女士（董事长）等。"
            ></input>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insLocalStudNo" className="my-FormLabel">
              Number of Students (Local)
            </label>
            <input
              type="number"
              className="form-control"
              id="insLocalStudNo"
              placeholder="Total number (actual or estimated) of local students"
              defaultValue={profile.insLocalStudNo}
            ></input>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insTeachingStaffNo" className="my-FormLabel">
              Number of Teaching Staff
            </label>
            <input
              type="text"
              className="form-control"
              id="insTeachingStaffNo"
              defaultValue={profile.insTeachingStaffNo}
              placeholder="Total number of teaching staff, E.g. 100"
            ></input>
          </div>

          <div className="form-group mt-1">
            <label htmlFor="insLocalStudNo" className="my-FormLabel">
              Number of Students (International)
            </label>
            <input
              type="number"
              className="form-control"
              id="insInterStudNo"
              placeholder="Total number (actual or estimated) of international students"
              defaultValue={profile.insInterStudNo}
            ></input>
          </div>
          <div className="form-group mt-1">
            <label htmlFor="insEmail" className="my-FormLabel">
              Primary Email
            </label>
            <input
              type="text"
              className="form-control"
              id="insEmail"
              placeholder="E.g: XXX@gmail.com"
              defaultValue={profile.insEmail}
            ></input>
            <small className="text-muted">Primary email address to receive notification/message</small>
          </div>
        </form>

        <div className="mt-3 my-BtnGrp">
          <button
            type="button"
            className="btn btn-success ms-3"
            id="submit"
            onClick={() => {
              save();
            }}
          >
            <IoIosSave /> Save
          </button>
        </div>
      </div>
    </LoggedInLayout>
  );
}
