
import { Modal } from "react-bootstrap";
import "./css/zoomImage.css"
import {
    FaTimes
} from "react-icons/fa"

export default function(props){
    return(
        // {props.src==="-1"? "d-none" : ""}
        <div className={props.src==="-1"? "d-none" : ""}>
            <div className="closeBtn"><FaTimes className="text-white" onClick={()=>props.onClose()}/></div>
            <div className="blackBg" onClick={()=>props.onClose()}></div>
            <div className="zoomImgContainer">
                <img src={props.src} width="80%" height="90%"/>
                {/* <p class="zoomImg-caption">zoomImg-caption</p> */}
            </div>

        </div>

    )
}