import LoggedInLayout from "../../layouts/loggedInLayout";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import {
  BsTrashFill,
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";

import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  FieldPath,
  orderBy,
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function SpecialtyPage(props) {
  const [user, loading, error] = useAuthState(auth);
  const [specialty, setSpecialty] = useState([]);
  const [ori, setOri] = useState([]);

  useEffect(() => {
    const getDbData = async (uid) => {
      const q = query(
        collection(db, `db_uni/${uid}/specialty`),
        where("order", "!=", -1),
        orderBy("order")
      );
      let d;
      let data = await getDocs(q);
      setSpecialty(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setOri(specialty);
    };

    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        getDbData(user.uid);
      }
    });

    return () => {
      setSpecialty([]);
      setOri([]);
    };
  }, []);

  function checkAddField() {
    let desc = $("#usrInput").val();
    let desc_cn = $("#usrInput_cn").val();
    if (desc == "" && desc_cn == "") {
      $("#addMsg").addClass("d-none");
    } else {
      $("#addMsg").removeClass("d-none");
    }
  }

  $(document).ready(function () {
    specialty.map((ach) => {
      $(`#${ach.order}-Desc`).val(ach.desc);
      $(`#${ach.order}-Desc_cn`).val(ach.desc_cn);
    });
  });

  function save() {
    let descUsr = $("#usrInput").val();
    let desc_cnUsr = $("#usrInput_cn").val();
    if (descUsr == "" && desc_cnUsr == "") {
      // do ntg
    } else {
      notify(
        "warning",
        'Please click "Add" or clear bottom field before save.',
        "Incomplete Input"
      );
      return;
    }

    let latestList = getUpdateList();
    setSpecialty(latestList);

    window.preloader(true);
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateSpecialty",
      data: {
        uid: user.uid,
        specialties: latestList,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  function revertChg() {
    let confirmChg = window.confirm("Are you sure to discard change made?");
    let a;
    confirmChg ? setSpecialty(ori) : (a = 1);
  }

  function swapItem(order) {
    let arr = getUpdateList();
    let total = arr.length;

    //swap with top
    if (order == 0) {
      notify("warning", "Cannot Swap Toppest Item!", "Swap Failed");
    } else {
      let temp = arr[order];
      arr[order] = {
        desc: arr[order - 1].desc,
        desc_cn: arr[order - 1].desc_cn,
        order: order,
        id: `specialty_${order}`,
      };
      arr[order - 1] = {
        desc: temp.desc,
        desc_cn: temp.desc_cn,
        order: order - 1,
        id: `specialty_${order - 1}`,
      };
    }
    setSpecialty(arr);
  }

  function deleteItem(order) {
    let arr = getUpdateList();
    let total = arr.length;

    if (order + 1 == total) {
      arr.pop();
    } else if (order >= 0) {
      for (var i = order; i < total; i++) {
        var ab = i;
        arr[i].order = i - 1;
        arr[i].id = `specialty_${i - 1}`;
        arr[i].desc = arr[ab].desc;
        arr[i].desc_cn = arr[ab].desc_cn;

        $(`#${order + 1}-Desc`).val("test order" + order);
        $(`#${order + 1}-Desc_cn`).val("");
      }
    }
    arr.splice(order, 1);
    setSpecialty(arr);
  }

  function addItem() {
    let arr = getUpdateList();
    let desc = $("#usrInput").val();
    let desc_cn = $("#usrInput_cn").val();
    let order = arr.length;
    let id = `specialty_${order}`;

    const addedArr = [{ desc: desc, desc_cn: desc_cn, order: order, id: id }];
    let newArr = arr.concat(addedArr);
    setSpecialty(newArr);

    $("#usrInput").focus();
    $("#usrInput").val("");
    $("#usrInput_cn").val("");
  }

  function getUpdateList() {
    let total = specialty.length;
    let arr = [];
    setSpecialty([]);

    for (var i = 0; i < total; i++) {
      let desc = $(`#${i}-Desc`).val();
      let desc_cn = $(`#${i}-Desc_cn`).val();
      arr.push({
        desc: desc,
        desc_cn: desc_cn,
        order: i,
        id: `specialty_${i}`,
      });

      $(`#${i}-Desc`).val(desc);
      $(`#${i}-Desc_cn`).val(desc_cn);
    }
    return arr;
  }

  return (
    <LoggedInLayout>
      <div className="contentContainer">
        <div className="contentTitle position-relative">
          <span>Edit Specialty</span>
        </div>

        <form className="contentBody">
          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">Specialty</th>
                  <th scope="col">Specialty(CN)</th>
                  <th scope="col" style={{ width: "5%" }}>
                    Order
                  </th>
                  <th scope="col" style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {specialty.map((ach) => {
                  return (
                    <tr key={ach.id}>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id={ach.order + "-Desc"}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id={ach.order + "-Desc_cn"}
                        ></input>
                      </td>
                      <td className="text-center align-middle">
                        {ach.order == 0 ? (
                          <button
                            type="button"
                            className="align-center btn-sm btn-secondary"
                            disabled
                          >
                            <BsFillArrowUpCircleFill className="mb-1" />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="align-center btn-sm btn-primary"
                            onClick={() => swapItem(ach.order, "up")}
                          >
                            <BsFillArrowUpCircleFill className="mb-1" />
                          </button>
                        )}

                        {/* <button
                          type="button"
                          className="align-center btn-sm btn-primary"
                        >
                          <BsFillArrowDownCircleFill className="mb-1" />
                        </button> */}
                      </td>
                      <td className="text-center align-middle">
                        <button
                          type="button"
                          className="btn-sm btn-danger"
                          onClick={() => {
                            deleteItem(ach.order);
                          }}
                        >
                          <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                <tr className="bg-info">
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id="usrInput"
                      placeholder="Add Special Feature"
                      onKeyUp={() => checkAddField()}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id="usrInput_cn"
                      onKeyUp={() => checkAddField()}
                      placeholder="填写特色"
                    ></input>
                  </td>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      className="btn-sm btn-primary mt-1 align-center"
                      onClick={() => {
                        addItem();
                      }}
                    >
                      Add
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="text-danger d-none" id="addMsg">
              <i>Please click "Add" to add items.</i>
            </span>
          </div>
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-success ms-3"
              id="submit"
              onClick={() => save()}
            >
              <IoIosSave /> Save
            </button>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
