import LoggedInLayout from "../../layouts/loggedInLayout";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { useEffect } from "react";

import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function InformationPage(props) {
  const [user, loading, error] = useAuthState(auth);

  function save() {
    window.preloader(true);
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateIntroduction",
      data: {
        uid: user.uid,
        intro: $("#insIntro").val(),
        intro_cn: $("#insIntro-cn").val(),
        publish: $("#publish").prop("checked"),
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  async function getIntro(tuid) {
    const getUIntro = await getDoc(
      doc(db, `db_uni/${tuid}/information`, "introduction")
    );
    if (getUIntro.exists()) {
      const data = getUIntro.data();
      $("#insIntro").val(data.insIntro);
      $("#insIntro-cn").val(data.insIntro_cn);
      $("#publish").prop("checked", data.publish);
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        window.scrollTo(0, 0);
        getIntro(user.uid);
      }
    });
  });

  return (
    <LoggedInLayout>
      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Edit Introduction</span>
        </div>

        <form className="contentBody">
          <h5 className="text-muted"><i>Introduce institution's background and special features</i></h5>
          <div className="form-group mt-3">
            <label htmlFor="insIntro" className="my-FormLabel">
              Introduction (ENG)
            </label>
            <textarea
              className="form-control"
              rows="10"
              placeholder="Introduce institution’s background and special features"
              id="insIntro"
            ></textarea>
          </div>
          <small className="text-muted">Number of text unlimited but preferably not more than 1000 words</small>

          <div className="form-group mt-1">
            <label htmlFor="insIntro-cn" className="my-FormLabel">
              Introduction (CN) / 介绍
            </label>
            <textarea
              className="form-control"
              rows="10"
              placeholder="请介绍学校的背景和特色"
              id="insIntro-cn"
            ></textarea>
          </div>
          <small className="text-muted">不限字数，但建议不超过1000字</small>


          <div className="form-group mt-3">
            <label htmlFor="publish" className="my-FormLabel">
              Publish:
            </label>
            <input
              type="checkbox"
              className="form-checkbox my-CheckBox ms-3"
              id="publish"
            ></input>
          </div>

          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              id="save"
              className="btn btn-success ms-3"
              onClick={() => {
                save();
              }}
            >
              <IoIosSave /> Save
            </button>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
