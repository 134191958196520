import LoggedInLayout from "../../layouts/loggedInLayout";
import { IoIosSave } from "react-icons/io";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import Modal from "../../components/modal-container";
import MultipleImgComponent from "../../components/uploadImages/uploadMultipleImages";

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function FacilityPage(props) {
  const [user, loading, error] = useAuthState(auth);
  const [facilities, setFacilities] = useState([]);

  const [curId, setCurId] = useState("");
  const [cur, setCur] = useState({});
  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);

  //---multipleimgs---
  const [userId, setUserId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  //-----------------------------------

  async function getDbData(uid) {
    const q = query(collection(db, `db_uni/${uid}/facility`));
    let data = await getDocs(q);
    setFacilities(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }
  
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/facility`),
          where("created_on", "!=","placeholder"),
          orderBy("created_on")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setFacilities([]);
          querySnapshot.forEach((doc) => {
            setFacilities((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });
  
        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/facility`),
            where("created_on", "!=", "placeholder"),
            orderBy("created_on")
          );
          let data = await getDocs(q);
          setFacilities(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          window.preloader(false);
        };
        getDbData();
      }
    });

    return () => {
      setUserId("");
      setFacilities([]);
      //unsub();
    };
  }, []);

  const beforeSave = (images) =>{// beforesave
    if(images != null){
      save(cur.id, images);
    }
  }

  function save(curId, inputImgs) {
    setSubmitted(true);
    window.preloader(true);
    let name = $("#name").val();
    let name_cn = $("#name_cn").val();
    let desc = $("#desc").val();
    let desc_cn = $("#desc_cn").val();
    let publish = $("#publish").prop("checked");

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyFacility",
      data: {
        action: action,
        uid: user.uid,
        facilityId: curId,
        name: name,
        name_cn: name_cn,
        desc: desc,
        desc_cn: desc_cn,
        fac_img: inputImgs,
        publish: publish
      },
      success: function (msg) {
        if (msg === "1") {
          alert(`New facility is added Successfully! Kindly check after 5 mins if change is not visible.`);
          notify("success", "New Facility is Created!", "Created Successfully");
          setShowModal(false);
        } 
        else if(msg === "2"){
          notify("success", "Change is updated!", "Updated Successfully");
          setShowModal(false);
        }else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  const deleteItem = async (tId) => {
    //prompt delete or not.
    const getUIntro = await deleteDoc(
      doc(db, `db_uni/${user.uid}/facility`, tId)
    );
    notify("success", `"${cur.fac_title}" is Deleted!`, "Deleted Successfully");
    setShowModal(false);
  };

  const popupModal = (id, uid) => {
    setCur({});
    setCurId("");

    if (id == "") {
      setAction("New");
    }
    else if(id=="Delete"){
      setAction("Delete");
      var localCur = facilities.find((item) => item.id == uid);
      setCur(localCur);
      setShowModal(true);
    }
    else {
      setAction("Edit");
      setCurId(id);
      getDetail(id);
    }
    setShowModal(true);
  }

  const getDetail = (id) => {
    setCur(facilities.find((item) => item.id == id));
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(cur.id);
        }}
        onDelete={() => {
          deleteItem(cur.id);
        }}
        itemName={cur.fac_title}
        action={action}
        title="Facility"
      >
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Facility Title
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            defaultValue={cur.fac_title}
            placeholder="Facility Title E:g: Library"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name_cn" className="my-FormLabel">
            Facility Title (CN)
          </label>
          <input
            type="text"
            className="form-control"
            id="name_cn"
            defaultValue={cur.fac_title_cn}
            placeholder="Facility Title(CN) E.g: 图书馆"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Description
          </label>
          <textarea
            type="text"
            className="form-control"
            id="desc"
            defaultValue={cur.fac_desc}
            placeholder="E.g: The reading space, which can accommodate up to 200 persons at any one time, has a collection of 100,000 books, wifi and discussion rooms."
            rows="5"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Description (CN)
          </label>
          <textarea
            type="text"
            className="form-control"
            id="desc_cn"
            defaultValue={cur.fac_desc_cn}
            placeholder="例：藏书10万册，可同时容纳200人的阅读空间，并备有无线上网和讨论室。"
            rows="5"
          ></textarea>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={cur.publish}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="cost" className="my-FormLabel">
            Preview Images
          </label>
          <div className="input-group">
            <MultipleImgComponent imgList={cur.fac_img} uid={userId} pageName={"facility"} onBeforeSubmit={(images)=>beforeSave(images)} submitted={submitted} onInit={() => {setSubmitted(false);}}/>
          </div>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Facility(s)</span>
        </div>

        <div className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addFacility"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Facility
            </button>
            </div>
          <div className="my-TableContainer table-responsive">
            <table className="table table-striped table-bordered table-hover commonTable">
              <thead>
                <tr className="text-center align-middle">
                  <th scope="col" width="5%">#</th>
                  <th scope="col" width="19%">Title</th>
                  <th scope="col" width="33%">Description</th>
                  <th scope="col" width="33%">Description (CN)</th>
                  <th scope="col" width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {facilities.map((facility, index) => {                              
                  return (
                    <tr className="text-center align-middle" key={index+1}>
                      <td>{index+1}</td>
                      <td>{facility.fac_title}<br/><i className="text-muted">{facility.fac_title_cn}</i></td>
                      <td>{facility.fac_desc.length > 75? `${facility.fac_desc.substring(0,73)}...`: facility.fac_desc}</td>
                      <td>{facility.fac_desc_cn.length > 40? `${facility.fac_desc_cn.substring(0,38)}...`: facility.fac_desc_cn}</td>
                      <td>
                        <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(facility.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal('Delete', facility.id);
                            }}
                          >
                            <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  );              
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </LoggedInLayout>
  );
}
