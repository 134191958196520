import React, { useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import routes from "../routes";

import "../css/sideNav.css";

export default function SideNav(props) {
    const location = useLocation();

    useEffect(() => {
        detectWidth();
        window.addEventListener("resize", detectWidth);
    }, []);

    useEffect(() => {
        toggleSideNav();

        function toggleSideNav() {
            if (window.innerWidth <= 700) {
                document.getElementById('sideNavMenu').style.display = "block";
                if (props.show) {
                    document.getElementById('sideNavMenu').style.width = "28%";
                }
                else {
                    document.getElementById('sideNavMenu').style.width = "0%";
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    const renderRoutes = routes.map((route) => {
        let returnValue;

        if (window.accessControl) {
            if (route.type === "primary") {
                if (location.pathname.includes(route.route)) {
                    returnValue = <li key={route.name} className="bg-primary bg-gradient chosen"><Link to={route.route}>{route.icon}<span>{route.name}</span></Link></li>;
                } else {
                    returnValue = <li key={route.name}><Link className="" to={route.route}>{route.icon}<span>{route.name}</span></Link></li>;
                }
            }
        } else {
            if (route.type === "primary" || route.type === "primarySpecial") {
                if (location.pathname.includes(route.route)) {
                    returnValue = <li key={route.name} className="bg-primary bg-gradient chosen"><Link to={route.route}>{route.icon}<span>{route.name}</span></Link></li>;
                } else {
                    returnValue = <li key={route.name}><Link className="" to={route.route}>{route.icon}<span>{route.name}</span></Link></li>;
                }
            }
        }
        return returnValue;
    });


    function detectWidth() {
        if (window.innerWidth <= 700) {
            document.getElementById('sideNavMenu').style.display = "none"
        }
        else {
            document.getElementById('sideNavMenu').style.display = "block";
            document.getElementById('sideNavMenu').style.width = "18%";
        }
    }


    return (
        <div className="navBarContainer shadow-sm" id="sideNavMenu">
            <div className="sideNavLogo"><img src={require('../assets/images/apple-icon.png')} alt="HighSchool.my" />&nbsp;成年 Highschool.my</div>
            <ul className="sideNavMenu">
                {renderRoutes}
            </ul>
        </div>
    );
}
