import LoggedInLayout from "../../layouts/loggedInLayout";
import Select from "react-select";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";

import Modal from "../../components/modal-container";
import ModalDelete from "../../components/modal-delete";
import { useEffect, useState, useRef } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  deleteDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function ProgrammePage(props) {
  const [user, loading, error] = useAuthState(auth);

  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  const [showCurName, setCurName] = useState("");
  const [progId, setprogId] = useState("");
  const [discDisabled, setDiscDisabled] = useState(true);
  const [ft, setFt] = useState(false);
  const [ftVal, setFtVal] = useState({});
  const [pt, setPt] = useState(false);
  const [ptVal, setPtVal] = useState({});
  const [feeFTLC, setfeeFTLC] = useState("");
  const [feeFTIN, setfeeFTIN] = useState("");
  const [feePTLC, setfeePTLC] = useState("");
  const [feePTIN, setfeePTIN] = useState("");

  const [progs, setProgs] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [studyFields, setStudyFields] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [levels, setLevels] = useState([]);

  const [filteredDiscs, setFilteredDiscs] = useState([]);
  const [curProg, setCurProg] = useState([]);
  const [selIntakeDates, setSelIntakeDates] = useState([]);
  const [selCampuses, setSelCampuses] = useState([]);
  const [selStudyFields, setSelStudyFields] = useState([]);
  const [selDisciplines, setSelDisciplines] = useState([]);
  const [defSelIntakeDates, setDefSelIntakeDates] = useState([]);
  const [defSelCampuses, setDefSelCampuses] = useState([]);
  const [defSelDisciplines, setDefSelDisciplines] = useState([]);
  const [defSelStudyFields, setDefSelStudyFields] = useState([]);

  let count = 0;
  let ct = 0;

  const months = [
    { value: "jan", label: "January" },
    { value: "feb", label: "February" },
    { value: "mar", label: "March" },
    { value: "apr", label: "April" },
    { value: "may", label: "May" },
    { value: "jun", label: "June" },
    { value: "jul", label: "July" },
    { value: "aug", label: "August" },
    { value: "sep", label: "September" },
    { value: "oct", label: "October" },
    { value: "nov", label: "November" },
    { value: "dec", label: "December" },
  ];

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const q = query(
        collection(db, `db_uni/${user.uid}/programme`),
        where("courseName", "not-in", ["", "placeholder"]),
        orderBy("courseName")
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        setProgs([]);
        ct = 0;
        querySnapshot.forEach((doc) => {
          setProgs((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
        });
      });

      const getDbData = async () => {
        window.preloader(true);
        const q = query(
          collection(db, `db_uni/${user.uid}/programme`),
          where("courseName", "not-in", ["", "placeholder"]),
          orderBy("courseName")
        );
        let data = await getDocs(q);
        setProgs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        window.preloader(false);
      };
      const getCampusData = async () => {
        window.preloader(true);
        const q = query(
          collection(db, `db_uni/${user.uid}/campus`),
          where("location", "not-in", ["", "placeholder"])
        );
        let data = await getDocs(q);
        setCampuses(
          data.docs.map((doc) => ({
            value: doc.id,
            label: doc.data().location,
          }))
        );

        window.preloader(false);
      };
      const getLevelData = async () => {
        window.preloader(true);
        const q = query(
          collection(db, `db_shared_resources/db_uni/db_uni_ProgLevel`)
        );
        let data = await getDocs(q);
        setLevels(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        window.preloader(false);
      };
      const getFieldData = async () => {
        window.preloader(true);
        const q = query(
          collection(db, `db_shared_resources/db_uni/db_uni_StudyField`)
        );
        let data = await getDocs(q);
        setStudyFields(
          data.docs.map((doc) => ({
            value: doc.id,
            label: `${doc.data().name}`,
          }))
        );
        window.preloader(false);
      };
      const getDisciplineData = async () => {
        window.preloader(true);
        const q = query(
          collection(db, `db_shared_resources/db_uni/db_uni_Discipline`)
        );
        let data = await getDocs(q);
        setDisciplines(
          data.docs.map((doc) => ({
            value: doc.id,
            label: doc.data().name,
            studyFieldId: doc.data().studyField,
          }))
        );
        setFilteredDiscs(disciplines);
        window.preloader(false);
      };
      getDbData();
      getCampusData();
      getLevelData();
      getFieldData();
      getDisciplineData();
    });

    return () => {
      //unsub();
    };
  }, []);

  const toggleDuration = (type) => {
    let checked = false;
    if (type == "ft") {
      checked = !ft;
      setFt(!ft);
    }
    else {
      checked = !pt;
      setPt(!pt);
    }

    if (checked) {

    }
    else {
      $(`#${type}Year`).val("");
      $(`#${type}Month`).val("");
    }
  }

  const chgCampuses = (e) => {
    let newAr = e.map((x) => x.value);
    setSelCampuses(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const chgStudyFields = (e) => {
    setDefSelStudyFields(e);
    setSelStudyFields(Array.isArray(e) ? e.map((x) => x.value) : []);
    let a = e.map((x) => x.value);
    filterDiscByStudyField(a);
  };
  const chgIntakeDates = (e) => {
    setSelIntakeDates(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const chgDisciplines = (e) => {
    setDefSelDisciplines(e);
    setSelDisciplines(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const filterDiscByStudyField = (arr) => {
    let newArr = [];
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < disciplines.length; j++) {
        if (disciplines[j].studyFieldId === arr[i]) {
          let newInput = {
            value: disciplines[j].value,
            label: `[${studyFields.find((x) => x.value === arr[i]).label}] ${disciplines[j].label
              }`,
            studyFieldId: disciplines[j].studyFieldId,
          };
          newArr.push(newInput);
        }
      }
    }
    setFilteredDiscs(newArr);
    setDiscDisabled(false);

    window.preloader(false);
  };

  const deleteItem = async (tId) => {
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/deleteProg",
      data: {
        uid: user.uid,
        progId: tId,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", `"${curProg.courseName}" is Deleted!`, "Deleted Successfully");
        } else {
          action == "New"
            ? notify("error", "Deletion Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });

    setShowModal(!showModal);
  };

  const initModal = () => {
    setCurProg([]);
    setSelStudyFields([]);
    setSelCampuses([]);
    setSelDisciplines([]);
    setSelIntakeDates([]);

    setDefSelStudyFields([]);
    setDefSelCampuses([]);
    setDefSelDisciplines([]);
    setDefSelIntakeDates([]);
    setDiscDisabled(true);

    setFt(false);
    setPt(false);
    setFtVal({});
    setPtVal({});
  };

  const popupModal = (id, uid) => {
    if (id == "") {
      setAction("New");
      initModal();
      setprogId("");
      setShowModal(true);
    }
    else if (id == "Delete") {
      initModal();
      var localCurProg = progs.find((item) => item.id == uid);
      setCurProg(localCurProg);
      setAction("Delete");
      setShowModal(true);
    }
    else {
      setAction("Edit");
      setprogId(id);
      getProgDetail(id);
    }
  };

  const getProgDetail = (uid) => {
    initModal();
    setShowModal(!showModal);
    var localCurProg = progs.find((item) => item.id == uid);
    setCurProg(localCurProg);
    setfeeFTLC(localCurProg["progFee_FullTime"].local);
    setfeeFTIN(localCurProg["progFee_FullTime"].inter);
    setfeePTLC(localCurProg["progFee_PartTime"].local);
    setfeePTIN(localCurProg["progFee_PartTime"].inter);
    let ft = localCurProg.ft_courseDuration.split('/');
    let pt = localCurProg.pt_courseDuration.split('/');

    setFtVal(ft);
    setPtVal(pt);

    if (ft.length == 2) {
      setFt(true);
      $("#ftYear").val(parseInt(ft[0]));
      $("#ftMonth").val(parseInt(ft[1]));
    }
    if (pt.length == 2) {
      setPt(true);
      $("#pt-year").val(parseInt(pt[0]));
      $("#pt-month").val(parseInt(pt[1]));
    }

    const setArray = (arr, id) => {
      var count = 0;
      for (var i = 0; i < arr.length; i++) {
        if (id === "intakeDates") {
          const item = months.find((item) => item.value == arr[i]);
          setDefSelIntakeDates((oldArr) => [...oldArr, item]);
        } else if (id === "progAvailableLocation") {
          let item = campuses.find((item) => item.value == arr[i]);
          setDefSelCampuses((oldArr) => [...oldArr, item]);
        } else if (id === "discipline") {
          let item = disciplines.find((item) => item.value == arr[i]);
          filterDiscByStudyField(localCurProg.studyField);
          const a = studyFields.find(x => x.value == item.studyFieldId);
          setDefSelDisciplines((oldArr) => [...oldArr, { value: item.value, label: `[${a.label}] ${item.label}`, studyFieldId: item.studyFieldId }]);

          count++;
        } else if (id === "studyField") {
          let item = studyFields.find((item) => item.value == arr[i]);
          setDefSelStudyFields((oldArr) => [...oldArr, item]);
        }
      }

      if (id == "discipline" && count > 0) {
        setDiscDisabled(false);
      }
    };

    setSelIntakeDates(localCurProg.intakeDates);
    setSelCampuses(localCurProg.progAvailableLocations);
    setSelStudyFields(localCurProg.studyField);
    setSelDisciplines(localCurProg.disciplines);

    setArray(localCurProg.intakeDates, "intakeDates");
    setArray(localCurProg.progAvailableLocations, "progAvailableLocation");
    setArray(localCurProg.studyField, "studyField");
    setArray(localCurProg.disciplines, "discipline");

    setShowModal(true);
  };

  const filterUnusedSF = () => {
    let discs = defSelDisciplines;
    let SFs = defSelStudyFields;
    let newSFArr = [];

    for (var i = 0; i < SFs.length; i++) {
      let isEmpty = 0;

      for (var j = 0; j < discs.length; j++) {
        if (SFs[i].value == discs[j].studyFieldId) {
          isEmpty++;
          break;
        }
      }

      if (isEmpty != 0) {
        newSFArr.push(SFs[i].value);
      }
    }
    return newSFArr;
  }

  const save = async (tId) => {
    window.preloader(true);
    action == "New" ? (tId = "") : (tId = tId);
    let SKU = $("#SKU").val();
    let courseName = $("#courseName").val();
    let courseName_cn = $("#courseName_cn").val();
    let progLevel = $("#progLevel").val();
    let intakeDates = selIntakeDates;
    let progAvailableLocations = selCampuses;
    let disciplines = selDisciplines;
    let studyField = filterUnusedSF();
    let fullTimeFees = { "local": $("#ft-fee1").val(), "inter": $("#ft-fee2").val() };
    let partTimeFees = { "local": $("#pt-fee1").val(), "inter": $("#pt-fee2").val() };

    let checkList = [
      intakeDates.length,
      progAvailableLocations.length,
      disciplines.length,
      studyField.length,
      SKU
    ];
    let checkListName = [
      "Intake Dates",
      "Locations",
      "Disciplines",
      "Study Fields",
      "Programme ID"
    ];

    for (var item in checkList) {
      if (checkList[item] == 0 || checkList[item] == "") {
        notify(
          "warning",
          `Please fill ${checkListName[item]}!`,
          "No Empty Field"
        );
        window.preloader(false);
        return;
      }
    }

    if (parseInt($("#ftMonth").val()) >= 12 || parseInt($("#ptMonth").val()) >= 12) {
      notify('warning', "Course Duration cannot more than 12! please retry it.", "Invalid Course Duration Month")
      window.preloader(false);
      return;
    }
    let noList = [parseInt($("#ftYear").val()), parseInt($("#ftMonth").val()), parseInt($("#ptYear").val()), parseInt($("#ptMonth").val())];
    for (var a in noList) {
      if (Number.isNaN(noList[a]) || noList[a] < 0) {
        noList[a] = 0;
      }
    }

    let fullTime_cd = noList[0] + '/' + noList[1];
    let partTime_cd = noList[2] + '/' + noList[3];
    if (noList[0] == 0 && noList[1] == 0) {
      fullTime_cd = "";
    }
    if (noList[3] == 0 && noList[2] == 0) {
      partTime_cd = "";
    }
    //noList[2] + '/' + noList[3];

    let progStartYr = $("#progStartYr").val();
    let qualificationName = $("#qualificationName").val();
    let qualificationName_cn = $("#qualificationName_cn").val();
    let awardingInstitution = $("#awardingInstitution").val();
    let awardingInstitution_cn = $("#awardingInstitution_cn").val();
    let recognitionQualification = $("#recognitionQualification").val();
    let recognitionQualification_cn = $("#recognitionQualification_cn").val();
    let major_subj = $("#major_subj").val();
    let major_sub_cn = $("#major_sub_cn").val();
    let elective_sub = $("#elective_sub").val();
    let elective_sub_cn = $("#elective_sub_cn").val();
    let entryRequirement = $("#entryRequirement").val();
    let entryRequirement_cn = $("#entryRequirement_cn").val();
    let gradNo = $("#gradNo").val();
    let progFee = $("#progFee").val();
    let applicationFee = $("#applicationFee").val();
    let registrationFee = $("#registrationFee").val();
    let othersFee = $("#othersFee").val();
    let contactInformation = $("#contactInformation").val();
    let contactInformation_cn = $("#contactInformation_cn").val();
    let publish = $("#publish").prop("checked");

    //Checking may needed
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyProgramme",
      data: {
        action: action,
        uid: user.uid,
        progId: progId,
        SKU: SKU,
        courseName: courseName,
        courseName_cn: courseName_cn,
        progLevel: progLevel,
        intakeDates: intakeDates,
        progAvailableLocations: progAvailableLocations,
        studyField: studyField,
        disciplines: disciplines,
        progStartYr: progStartYr,
        qualificationName: qualificationName,
        qualificationName_cn: qualificationName_cn,
        awardingInstitution: awardingInstitution,
        awardingInstitution_cn: awardingInstitution_cn,
        recognitionQualification: recognitionQualification,
        recognitionQualification_cn: recognitionQualification_cn,
        ft_courseDuration: fullTime_cd,
        pt_courseDuration: partTime_cd,
        progFee_FullTime: fullTimeFees,
        progFee_PartTime: partTimeFees,
        major_subj: major_subj,
        major_sub_cn: major_sub_cn,
        elective_sub: elective_sub,
        elective_sub_cn: elective_sub_cn,
        entryRequirement: entryRequirement,
        entryRequirement_cn: entryRequirement_cn,
        gradNo: gradNo,
        progFee: progFee,
        applicationFee: applicationFee,
        registrationFee: registrationFee,
        othersFee: othersFee,
        contactInformation: contactInformation,
        contactInformation_cn: contactInformation_cn,
        publish: publish,
      },
      success: function (msg) {
        if (msg === "1") {
          alert(`New programme is added Successfully! Kindly check after 5 mins if change is not visible.`);
          notify(
            "success",
            "New Programme is created!",
            "Created Successfully"
          );
          setShowModal(!showModal);
        } else if (msg === "2") {
          notify("success", "Change is updated!", "Updated Successfully");
          setShowModal(!showModal);
        } else {
          action == "New"
            ? notify("error", "Creation Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        notify("error", "Server Error 400.", "Error");
        window.preloader(false);
      },
    });

    setShowModal(!showModal);
  };
  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(progId);
        }}
        onDelete={() => {
          deleteItem(curProg.id);
        }}
        itemName={curProg.courseName}
        action={action}
        title="Programme"
      >
        <div className="form-group mt-1">
          <label htmlFor="SKU" className="my-FormLabel">
            Programme ID
          </label>
          <input
            type="text"
            className="form-control"
            id="SKU"
            defaultValue={curProg.SKU}
            placeholder="Programme ID"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="courseName" className="my-FormLabel">
            Course Title
          </label>
          <input
            type="text"
            className="form-control"
            id="courseName"
            defaultValue={curProg.courseName}
            placeholder="E.g. Bachelor of Business Administration programme"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="courseName_cn" className="my-FormLabel">
            Course Title (CN) / 课程名称
          </label>
          <input
            type="text"
            className="form-control"
            id="courseName_cn"
            defaultValue={curProg.courseName_cn}
            placeholder="例：企业管理商学士课程"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="progLevel" className="my-FormLabel">Program Level</label>
          <select
            id="progLevel"
            className="form-select"
            defaultValue={curProg.progLevel}
          >
            <option value="">Select Program Level</option>
            {levels.map((level) => {
              return (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group mt-1">
          <label className="my-FormLabel">Enrollment Status</label>
          <div className="input-group">
            <label className="me-4 my-FormLabel">Full Time? <span className={ft ? "" : "d-none"}>Course Duration:</span></label>
            <div className="input-group-prepend">
              <div className="input-group-text" style={{ height: "100%" }}>
                <input id="ft-bool" checked={ft} onChange={() => toggleDuration('ft')} type="checkbox"></input>
              </div>
            </div>

            <input id="ftYear" type="number" disabled={!ft} className="form-control" defaultValue={parseInt(ftVal[0])}></input>
            <div className="input-group-prepend">
              <div className="input-group-text bg-light" style={{ height: "100%" }} placeholder="Year">
                Year(s)
              </div>
            </div>

            <input id="ftMonth" type="number" disabled={!ft} className="form-control" defaultValue={parseInt(ftVal[1])}></input>
            <div className="input-group-prepend">
              <div className="input-group-text bg-light" style={{ height: "100%" }} placeholder="Month">
                Month(s)
              </div>
            </div>
          </div>

          <div className="input-group mt-3">
            <label className="me-4 my-FormLabel">Part Time? <span className={pt ? "" : "d-none"}>Course Duration:</span></label>
            <div className="input-group-prepend">
              <div className="input-group-text" style={{ height: "100%" }}>
                <input id="pt-bool" checked={pt} onChange={() => toggleDuration('pt')} type="checkbox"></input>
              </div>
            </div>

            <input id="ptYear" type="number" disabled={!pt} className="form-control" defaultValue={parseInt(ptVal[0])}></input>
            <div className="input-group-prepend">
              <div className="input-group-text bg-light" style={{ height: "100%" }} placeholder="Year">
                Year(s)
              </div>
            </div>

            <input id="ptMonth" type="number" disabled={!pt} className="form-control" defaultValue={parseInt(ptVal[1])}></input>
            <div className="input-group-prepend">
              <div className="input-group-text bg-light" style={{ height: "100%" }} placeholder="Month">
                Month(s)
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="intakeDates" className="my-FormLabel">
            招生月份 / Dates of student intake
          </label>
          <Select
            id="intakeDates"
            isMulti
            options={months}
            defaultValue={defSelIntakeDates}
            onChange={chgIntakeDates}
          />
        </div>
        <div className="form-group mt-1">
          <label htmlFor="progAvailableLocation" className="my-FormLabel">
            上课地点 / Locations where classes are held
          </label>
          <Select
            isMulti
            options={campuses}
            defaultValue={defSelCampuses}
            onChange={chgCampuses}
          />
        </div>
        <div className="form-group mt-1">
          <label htmlFor="studyField" className="my-FormLabel">领域 / Study Field</label>
          <Select
            isMulti
            options={studyFields}
            value={defSelStudyFields}
            onChange={chgStudyFields}
          />
        </div>
        <div className="form-group mt-1">
          <label htmlFor="discipline" className="my-FormLabel">学科 / Discipline</label>
          <Select
            isMulti
            options={filteredDiscs}
            isDisabled={discDisabled}
            value={defSelDisciplines}
            onChange={chgDisciplines}
          />
        </div>
        <div className="form-group mt-1">
          <label htmlFor="progStartYr" className="my-FormLabel">
            起始年份 / Year in which the course was started
          </label>
          <input
            type="number"
            className="form-control"
            id="progStartYr"
            placeholder="E.g: 1983"
            defaultValue={curProg.progStartYr}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="qualificationName" className="my-FormLabel">
            颁发资格（英文）/ Qualification awarded (in English)
          </label>
          <input
            type="text"
            className="form-control"
            id="qualificationName"
            defaultValue={curProg.qualificationName}
            placeholder="E.g. Bachelor of Business Administration"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="qualificationName_cn" className="my-FormLabel">
            颁发资格（中文）/ Qualification awarded (in Chinese)
          </label>
          <input
            type="text"
            className="form-control"
            id="qualificationName_cn"
            defaultValue={curProg.qualificationName_cn}
            placeholder="例：企业管理商学士学位"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="qualificationName" className="my-FormLabel">
            颁发机构/ Awarding institution
          </label>
          <input
            type="text"
            className="form-control"
            id="awardingInstitution"
            defaultValue={curProg.awardingInstitution}
            placeholder="E.g. XX University, XX College, XX Qualification Certification Organization etc."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="qualificationName_cn" className="my-FormLabel">
            颁发机构（中文）/ Awarding institution (in Chinese)
          </label>
          <input
            type="text"
            className="form-control"
            id="awardingInstitution_cn"
            defaultValue={curProg.awardingInstitution_cn}
            placeholder="例：XX大学、XX学院、XX资格认证机构，等等。"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="recognitionQualification" className="my-FormLabel">
            资格承认（英文）/ Recognition of qualification (in English)
          </label>
          <input
            type="text"
            className="form-control"
            id="recognitionQualification"
            defaultValue={curProg.recognitionQualification}
            placeholder="E.g. Malaysian Qualifications Agency (MQA)"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="recognitionQualification_cn" className="my-FormLabel">
            资格承认（中文）/ Recognition of qualification (in Chinese)
          </label>
          <input
            type="text"
            className="form-control"
            id="recognitionQualification_cn"
            defaultValue={curProg.recognitionQualification_cn}
            placeholder="例：马来西亚学术资格鉴定机构"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="major_subj" className="my-FormLabel">
            主修科目（英文）/ Major Subjects (in English)
          </label>
          <textarea
            className="form-control"
            rows="5"
            placeholder="Major subjects and required projects (E.g. special project, dissertation, internship, community service etc.)"
            id="major_subj"
            defaultValue={curProg.major_subj}
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="major_sub_cn" className="my-FormLabel">
            主修科目（中文）/ Major Subjects (in Chinese)
          </label>
          <textarea
            className="form-control"
            rows="5"
            placeholder="课程中的主修科目和规定性的作业，（例如：专案、论文、实习、社区服务等）。"
            id="major_sub_cn"
            defaultValue={curProg.major_sub_cn}
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="elective_sub" className="my-FormLabel">
            选修科目（英文）/ Elective Subjects (in English)
          </label>
          <input
            type="text"
            className="form-control"
            id="elective_sub"
            defaultValue={curProg.elective_sub}
            placeholder="例：马来西亚学术资格鉴定机构"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="elective_sub_cn" className="my-FormLabel">
            选修科目（中文）/ Elective Subjects (in Chinese)
          </label>
          <input
            type="text"
            className="form-control"
            id="elective_sub_cn"
            defaultValue={curProg.elective_sub_cn}
            placeholder="例：马来西亚学术资格鉴定机构"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="entryRequirement" className="my-FormLabel">
            学历与基本要求（英文）/ Entry Requirements (in English)
          </label>
          <textarea
            type="text"
            className="form-control"
            rows="5"
            placeholder="E.g. SPM: 5 credits / including credits in English and Mathematics."
            id="entryRequirement"
            defaultValue={curProg.entryRequirement}
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="entryRequirement_cn" className="my-FormLabel">
            学历与基本要求（中文）/ Entry Requirements (in Chinese)
          </label>
          <textarea
            type="text"
            rows="5"
            className="form-control"
            id="entryRequirement_cn"
            defaultValue={curProg.entryRequirement_cn}
            placeholder="例：SPM：5科优等 / 包括英文和数学优等。"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="gradNo" className="my-FormLabel">
            毕业生人数 / Number of Graduates
          </label>
          <input
            type="number"
            className="form-control"
            id="gradNo"
            defaultValue={curProg.gradNo}
            placeholder="Total number of graduates for the course from the earliest batch to the latest batch."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label className="my-FormLabel">Tuition Fee</label>
          <div className={ft ? "input-group row" : "d-none"}>
            <label className="me-4 my-FormLabel">Full Time</label>
            <div className="col input-group">
              <label className="me-4 my-FormLabel">Local</label>
              <input
                type="text"
                className="form-control"
                id="ft-fee1"
                defaultValue={feeFTLC}
                placeholder="Tuition Fee for Local"
              ></input>
            </div>
            <div className="col input-group">
              <label className="me-4 my-FormLabel">International </label>
              <input
                type="text"
                className="form-control"
                id="ft-fee2"
                defaultValue={feeFTIN}
                placeholder="Tuition Fee for International"
              ></input>
            </div>
          </div>

          <div className={pt ? "input-group row" : "d-none"}>
            <label className="me-4 my-FormLabel">Part Time</label>
            <div className="col input-group">
              <label className="me-4 my-FormLabel">Local</label>
              <input
                type="text"
                className="form-control"
                id="pt-fee1"
                defaultValue={feePTLC}
                placeholder="Tuition Fee for Local"
              ></input>
            </div>
            <div className="col input-group">
              <label className="me-4 my-FormLabel">International </label>
              <input
                type="text"
                className="form-control"
                id="pt-fee2"
                defaultValue={feePTIN}
                placeholder="Tuition Fee for International"
              ></input>
            </div>
          </div>
        </div>

        <div className="form-group mt-1">
          <label htmlFor="applicationFee" className="my-FormLabel">
            申请费/ Application Fee
          </label>
          <input
            type="text"
            className="form-control"
            id="applicationFee"
            defaultValue={curProg.applicationFee}
            placeholder="E.g. RM300"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="othersFee" className="my-FormLabel">
            注册费 / Registration Fee
          </label>
          <input
            type="text"
            className="form-control"
            id="registrationFee"
            defaultValue={curProg.registrationFee}
            placeholder="E.g. RM1500 per year ;RM750 per semester."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="country" className="my-FormLabel">
            其它费用/ Other Fees
          </label>
          <input
            type="text"
            className="form-control"
            id="othersFee"
            defaultValue={curProg.othersFee}
            placeholder="Other miscellaneous fees for each semester, E.g. laboratory fee / RM250, material fee / RM500 etc."
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="contactInformation" className="my-FormLabel">
            课程咨询（英文）/ Contact information for Inquiry (in English)
          </label>
          <textarea
            type="text"
            className="form-control"
            rows="5"
            id="contactInformation"
            defaultValue={curProg.contactInformation}
            placeholder="Contact of course inquiry department, contact person and contact details etc"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="contactInformation_cn" className="my-FormLabel">
            课程咨询（中文）/ Contact information for Inquiry (in Chinese)
          </label>
          <textarea
            type="text"
            className="form-control"
            rows="5"
            id="contactInformation_cn"
            defaultValue={curProg.contactInformation_cn}
            placeholder="请列出课程咨询单位、联络人和联络方式等。"
          ></textarea>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={curProg.publish}
          ></input>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle">
          <span>Manage Program(s)</span>
        </div>

        <form className="contentBody">
         <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addProgramme"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Programme
            </button>
          </div>

          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Title</th>
                  <th scope="col">Title(CN)</th>
                  <th scope="col">Level</th>
                  <th scope="col">Discipline</th>
                  <th scope="col">Campus</th>
                  <th scope="col" className="d-none">Budget</th>
                  <th scope="col">publish</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {progs.length == 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      Empty
                    </td>
                  </tr>
                ) : (
                  progs.map((doc) => {
                    count = count + 1;
                    return (
                      <tr key={count}>
                        <th scope="row">{count}</th>
                        <td>{doc.courseName}</td>
                        <td>{doc.courseName_cn}</td>
                        <td>
                          {levels.map((level) => {
                            if (level.typeId === doc.progLevel)
                              return `${level.name}`;
                          })}
                        </td>
                        <td>
                          {doc.disciplines.map((discDoc, i) => {
                            let a = "";
                            {
                              disciplines.map((disc) => {
                                if (disc.value === discDoc) {
                                  if (i > 0) {
                                    a += ", ";
                                  }
                                  a += disc.label;
                                }
                              });
                            }
                            return a;
                          })}
                        </td>
                        <td>
                          {doc.progAvailableLocations.map((loc,i) => {
                            var a = "";
                            {
                              campuses.map((campus) => {
                                if (campus.value === loc) {
                                  if(i>0){
                                    a += ", ";
                                  }
                                  a += `${campus.label}\n`;
                                }
                              });
                            }
                            return a;
                          })}
                        </td>
                        {/* <td className="d-none"></td> */}
                        <td>{doc.publish ? "Active" : "Inactive"}</td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(doc.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal('Delete', doc.id);
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
