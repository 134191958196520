import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/profile.css";
import Modal from "../../components/modal-container";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { useEffect, useState} from "react";
import '../../components/MapComponent/mapComponent.css';
import MyGoogleMap from '../../components/MapComponent/MyGoogleMap';

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
  FieldPath,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function CampusPage(props) {
  const [user] = useAuthState(auth);

  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  const [locId, setlocId] = useState("");
  const [campuses, setCampuses] = useState([]);
  const [curCampus, setCurCampus] = useState({});
  const [dbMainCampus, setMainCampus] = useState({});
  const [curLng, setCurLng] = useState(0);
  const [curLat, setCurLat] = useState(0);

  let count = 0;

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/campus`),
          where("location", "!=", ""),
          orderBy("location")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setCampuses([]);
          setMainCampus({});
          let arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({ ...doc.data(), id: doc.id });
          });
          setCampuses(arr);
          arr.map((camp) => {
            if (camp.mainCampus) {
              setMainCampus(camp);
            }
          });
        });

        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/campus`),
            where("location", "!=", "")
          );
          let data = await getDocs(q);
          let arr = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setCampuses(arr);

          window.preloader(false);
        };
        getDbData();

        return () => {
          unsub();
        };
      }
    });
  }, []);

  const deleteItem = async (tlocId) => {
    //prompt delete or not.
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/deleteCampus",
      data: {
        uid: user.uid,
        campusId: tlocId,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", `"${curCampus.location}" is Deleted!`, "Deleted Successfully");
          setShowModal(!showModal);
        } else {
          notify("error", "Deletion Failed. Please contact admin", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  };

  const setAddress = (addr, longitud, lattitud) => {

  }

  const popupModal = (id, uid) => {
    setCurLat(0);
    setCurLng(0);

    if (id == "") {
      setCurCampus({});
      setAction("New");
      setlocId("");
    }
    else if (id == "Delete") {
      setCurCampus({});
      setAction("Delete");
      var localCurCampus = campuses.find((item) => item.id == uid);
      setCurCampus(localCurCampus);
      setShowModal(!showModal);
      return;
    } else {
      setAction("Edit");
      setlocId(id);
      getCampusDetail(id);
    }
    setShowModal(true);
  };

  const getCampusDetail = (id) => {
    let curCam = campuses.find((item) => item.id == id);
    setCurCampus(curCam);
    setCurLng(curCam.coordinate._long);
    setCurLat(curCam.coordinate._lat);
  };

  const updateAddr = (addr, lat, lng) => {
    if (curLat != lat && curLng != lng) {
      setCurLat(lat);
      setCurLng(lng);
    }
    //console.log(addr);
  }

  const save = async (locId) => {
    window.preloader(true);
    locId == "New" ? (locId = "") : (locId = locId);
    let location = $("#location").val();
    let location_cn = $("#location_cn").val();
    let addressline1 = $("#addressline1").val();
    let addressline2 = $("#addressline2").val();
    let addressline3 = $("#addressline3").val();
    let postcode = $("#postcode").val();
    let city = $("#city").val();
    let state = $("#state").val();
    let country = $("#country").val();
    let phone_front = $("#phone_front").val();
    let phone = $("#phone").val();
    let website = $("#website").val();
    let email = $("#email").val();
    let whatsapp = $("#whatsapp").val();
    let fbPage = $("#fbPage").val();
    let fbUsername = $("#fbUsername").val();
    let mainCampus = $("#mainCampus").prop("checked");
    let publish = $("#publish").prop("checked");

    //Checking may needed
    if (dbMainCampus.location != null && dbMainCampus.id != locId) {
      mainCampus = false;
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyCampus",
      data: {
        action: action,
        uid: user.uid,
        locId: locId,
        location: location,
        location_cn: location_cn,
        website: website,
        addressline1: addressline1,
        addressline2: addressline2,
        addressline3: addressline3,
        postcode: postcode,
        lng: curLng,
        lat: curLat,
        city: city,
        state: state,
        country: country,
        phone_front: phone_front,
        phone: phone,
        website: website,
        email: email,
        whatsapp: whatsapp,
        fbPage: fbPage,
        fbUsername: fbUsername,
        mainCampus: mainCampus,
        publish: publish,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "New Campus is created!", "Created Successfully");
          alert(`New campus is added Successfully! Kindly check after 5 mins if change is not visible.`);
        } else if (msg === "2") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          action == "New"
            ? notify("error", "Creation Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
    setShowModal(!showModal);
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(locId);
        }}
        onDelete={() => {
          deleteItem(curCampus.id);
        }}
        itemName={curCampus.location}
        action={action}
        title="Campus"
      >
        <div className="form-group mt-1">
          <label htmlFor="location" className="my-FormLabel">
            Location of Campus
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            defaultValue={curCampus.location}
            placeholder="Example: Petaling Jaya"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="location_cn" className="my-FormLabel">
            Location of Campus (in Chinese)
          </label>
          <input
            type="text"
            className="form-control"
            id="location_cn"
            defaultValue={curCampus.location_cn}
            placeholder="例：八打灵再也"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="addressline1" className="my-FormLabel">
            Address
          </label>
          <input
            type="text"
            className="form-control"
            id="addressline1"
            defaultValue={curCampus.addressline1}
            placeholder="Address Line 1"
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="addressline2"
            defaultValue={curCampus.addressline2}
            placeholder="Address Line 2"
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="addressline3"
            defaultValue={curCampus.addressline3}
            placeholder="Address Line 3 (Optional)"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="postcode" className="my-FormLabel">
            Postcode
          </label>
          <input
            type="text"
            className="form-control"
            id="postcode"
            defaultValue={curCampus.postcode}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="city" className="my-FormLabel">
            City
          </label>
          <input
            type="text"
            className="form-control"
            id="city"
            defaultValue={curCampus.city}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="state">State (if applicable)</label>
          <select
            id="state"
            className="form-select"
            defaultValue={curCampus.state}
          >
            <option value="" disabled>
              State 学校的州属
            </option>
            <option value="JHR">Johor 柔佛</option>
            <option value="KDH">Kedah 吉打</option>
            <option value="KTN">Kelantan 吉兰丹</option>
            <option value="MLK">Melaka 马六甲</option>
            <option value="NSN">Negeri Sembilan 森美兰</option>
            <option value="PHG">Pahang 彭亨</option>
            <option value="PNG">Penang 槟城</option>
            <option value="PRK">Perak 霹雳</option>
            <option value="PLS">Perlis 玻璃市</option>
            <option value="SBH">Sabah 沙巴</option>
            <option value="SWK">Sarawak 砂拉越</option>
            <option value="SGR">Selangor 雪兰莪</option>
            <option value="TRG">Terengganu 登嘉楼</option>
            <option value="KUL">W.P. Kuala Lumpur 吉隆坡</option>
            <option value="PJY">W.P. Putrajaya 布城</option>
            <option value="LBN">W.P. Labuan 纳闽</option>
            <option value="OTR">Others 其它</option>
          </select>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="country" className="my-FormLabel">
            Country
          </label>
          <input
            type="text"
            className="form-control"
            id="country"
            defaultValue={
              curCampus.country === "" || curCampus.country === null
                ? "Malaysia"
                : curCampus.country
            }
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="location" className="my-FormLabel">
            Coordinate
          </label>
          <div className="main-wrapper mb-12" style={{ height: "40vh", paddingBottom: '5vh', textAlign: 'center' }}>
            <MyGoogleMap onHandleAddress={(a, b, c) => { updateAddr(a, b, c) }} curLong={curLng} curLat={curLat} />
          </div>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="phone" className="my-FormLabel">
            Phone
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <select
                id="phone_front"
                className="form-select"
                defaultValue={
                  curCampus.phone_front === "" ? "+60" : curCampus.phone_front
                }
              >
                <option value="+60">+60</option>
                <option value="+80">+80</option>
                <option value="+866">+866</option>
              </select>
            </div>
            <input
              type="text"
              className="form-control"
              id="phone"
              defaultValue={curCampus.phone}
            ></input>
          </div>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="email" className="my-FormLabel">
            Email
          </label>
          <input
            type="text"
            className="form-control"
            id="email"
            defaultValue={curCampus.email}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="website" className="my-FormLabel">
            Website
          </label>
          <input
            type="text"
            className="form-control"
            id="website"
            defaultValue={curCampus.website}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="whatsapp" className="my-FormLabel">
            Whatsapp URL
          </label>
          <input
            type="text"
            className="form-control"
            id="whatsapp"
            defaultValue={curCampus.whatsapp}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="fbPage" className="my-FormLabel">
            Facebook Page URL
          </label>
          <input
            type="text"
            className="form-control"
            id="fbPage"
            defaultValue={curCampus.fbPage}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="fbUsername" className="my-FormLabel">
            Facebook Username
          </label>
          <input
            type="text"
            className="form-control"
            id="fbUsername"
            defaultValue={curCampus.fbUsername}
          ></input>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="mainCampus" className="my-FormLabel">
            Main Campus? :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="mainCampus"
            defaultChecked={curCampus.mainCampus}
            disabled={
              dbMainCampus.id == null || curCampus.mainCampus ? false : true
            }
          ></input>
          {dbMainCampus.id == null || curCampus.mainCampus ? (
            ""
          ) : (
            <span className="ms-4">
              <i>
                Current: <b>{dbMainCampus.location}</b>
              </i>
            </span>
          )}
        </div>
        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={curCampus.publish}
          ></input>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Campus(s)</span>
        </div>

        <form className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addCampus"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Campus
            </button>
          </div>

          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr className="text-center">
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Name(cn)</th>
                  <th scope="col">Address</th>
                  <th scope="col">State</th>
                  <th scope="col">Country</th>
                  <th scope="col">Main Campus</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {campuses.length == 0 ? (
                  <tr>
                    <td colSpan="9">Empty</td>
                  </tr>
                ) : (
                  campuses.map((campus) => {
                    count = count + 1;

                    return (
                      <tr key={count}>
                        <th scope="row">{count}</th>
                        <td>{campus.location}</td>
                        <td>{campus.location_cn}</td>
                        <td>
                          {campus.addressline1},{campus.addressline2},
                          {campus.addressline3}
                        </td>
                        <td>{campus.state}</td>
                        <td>{campus.country}</td>
                        <td>{campus.mainCampus ? "Yes" : "No"}</td>
                        <td>{campus.publish ? "Active" : "Inactive"}</td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(campus.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal('Delete', campus.id);
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </LoggedInLayout >
  );
}