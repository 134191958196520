import { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/font-awesome.min.css";
import "./css/login.css";
import $, { data, extend } from "jquery";
import ForgetPassword from "./components/ForgetPassword";
import PopupMsg from "./components/PopupMsg";

import { auth, db } from "./../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import * as firebaseAuth from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { faBriefcaseClock } from "@fortawesome/free-solid-svg-icons";
import CryptoJS from 'crypto-js';

function SignInPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showFgtPw, setShowFgtPw] = useState(false);
  const [showPopupMsg, setShowPopupMsg] = useState(false);
  const [loginMsg, setLoginMsg] = useState(null);


  //DBs
  const getUsersByUsername = async (credential, passphrase) => {
    const userCollRef = query(
      collection(db, "db_uni"),
      where("terminate", "==", false),
      where("username", "==", credential)
    );
    const querySnapshot = await getDocs(userCollRef);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((userData) => {
        let email = userData.data().email;
        proceedLogin(email, passphrase);
      });
    } else {
      PopupMsgHandler("Invalid login credentails!<br>用户名或密码错误！");
    }
  };

  const getUsersByEmail = async (credential, passphrase) => {
    const userCollRef = query(
      collection(db, "db_uni"),
      where("terminate", "==", false),
      where("email", "==", credential)
    );
    const querySnapshot = await getDocs(userCollRef);
    if (!querySnapshot.empty) {
      proceedLogin(credential, passphrase);
    } else {
      PopupMsgHandler("Invalid login credentails!<br>用户名或密码错误！");
    }
  };

  //handlers
  const changeHandler = ({ target }) => {
    setUsername(target.value.toUpperCase());
  };

  const loginHandler = (credential, passphrase) => {
    window.preloader(false);
    credential = credential.toLowerCase();
    if (credential !== "" && passphrase !== "") {
      if (credential.includes("@")) {
        getUsersByEmail(credential, passphrase);
      } else {
        getUsersByUsername(credential, passphrase);
      }
    } else {
      PopupMsgHandler(
        "Login credentials must not be empty! <br> 登录信息不可为空!"
      );
    }
  };

  const forgetPwHandler = () => {
    setShowFgtPw(!showFgtPw);
  };

  const PopupMsgHandler = (msg) => {
    if (msg == "" || msg == undefined || msg == null) {
      setLoginMsg(null);
    } else {
      setLoginMsg(msg);
    }
    setShowPopupMsg(!showPopupMsg);
  };

  function proceedLogin(credential, passphrase) {
    let rememberMe = document.getElementById("rememberMe").checked;
    if (rememberMe) {
      firebaseAuth.signInWithEmailAndPassword(auth, credential, passphrase);
    } else {
      firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence).then(() => {
        return firebaseAuth.signInWithEmailAndPassword(auth, credential, passphrase).catch((error) => {
          switch (error.code) {
            case "auth/wrong-password":
              PopupMsgHandler(
                "Invalid login credentials!<br>用户名或密码错误!"
              );
              break;
            case "auth/too-many-requests":
              PopupMsgHandler(
                "Your account is locked temporarily due to numerous failed attempts!<br>系统侦察到可疑登录行为,您的账号已被暂时封锁,请稍后再试!"
              );
              break;
          }
        });
      });
    }
  }

  let [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userEM = queryParams.get("auth");
    const userPW = queryParams.get("x");
    const adminUN = queryParams.get("a");
    if (user) {
      navigate("/Dashboard");
    } else {
      if (userEM !== null && userPW !== null) {
        window.accessControl = true;
        window.accessControlUser = (adminUN) ? `${decodeURIComponent(adminUN)}` : "ADMIN.HIGHSCHOOL.MY";
        const decEM = CryptoJS.AES.decrypt(decodeURIComponent(userEM), "acem").toString(CryptoJS.enc.Utf8);
        const decPW = CryptoJS.AES.decrypt(decodeURIComponent(userPW), "acpw").toString(CryptoJS.enc.Utf8);
        proceedLogin(decEM, decPW);
      }
    }

    return () => { };
  }, [user]);

  return (
    <>
      <div className="limiter">
        <div className="container-hs-admin-login">
          <div className="wrap-hs-admin-login">
            <div className="hs-admin-login-form-title">
              <span className="hs-admin-login-form-title-1">
                University Portal
              </span>
            </div>
            <form className="hs-admin-login-form validate-form">
              <div
                className="wrap-hs-admin-input validate-input m-b-26"
                data-validate="Username is required"
              >
                <span className="label-hs-admin-input">Username or Email</span>
                <input
                  className="hs-admin-input"
                  type="text"
                  placeholder="Enter username or email"
                  value={username}
                  onChange={changeHandler}
                />
                <span className="focus-hs-admin-input"></span>
              </div>

              <div
                className="wrap-hs-admin-input validate-input m-b-18"
                data-validate="Password is required"
              >
                <span className="label-hs-admin-input">Password</span>
                <input
                  className="hs-admin-input"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="focus-hs-admin-input"></span>
              </div>

              <div className="flex-sb-m w-full p-b-30">
                <div className="hs-admin-contact-form-checkbox">
                  <input
                    className="input-hs-admin-checkbox"
                    id="rememberMe"
                    type="checkbox"
                    name="remember-me"
                  />
                  <label
                    className="label-hs-admin-checkbox"
                    htmlFor="rememberMe"
                  >
                    Remember me
                  </label>
                </div>

                <div>
                  <a onClick={forgetPwHandler} className="txt1" id="frgtpw">
                    Forgot Password?
                  </a>
                </div>
              </div>

              <div className="container-hs-admin-login-form-btn">
                <button
                  type="button"
                  className="hs-admin-login-form-btn"
                  onClick={() => loginHandler(username, password)}
                >
                  Login
                </button>
              </div>
            </form>
            <p className="copyright-note">Powered by UHS TECHNOLOGY</p>
          </div>
        </div>
      </div>
      <ForgetPassword showModal={showFgtPw} onShow={() => forgetPwHandler()} />
      <PopupMsg
        showModal={showPopupMsg}
        message={loginMsg}
        onShow={() => PopupMsgHandler()}
      />
    </>
  );
}

export default SignInPage;
