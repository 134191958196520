import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightFromBracket, faBell } from "@fortawesome/free-solid-svg-icons";
import { FaHome } from 'react-icons/fa';
import { notify } from "../components/notify";
import FirstTimeLogin from "./../pages/auth/components/FirstTimeLogin";
import { auth, db } from "./../firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import $ from "jquery";
import moment from 'moment-timezone'

import "../css/header.css"

export default function Header(props) {
    const [showFirstTimeLoginModal, setShowFirstTimeLoginModal] = useState(false);
    const [value, setValue] = useState();
    const navigate = useNavigate();

    const firstTimeLoginHandler = () => {
        setShowFirstTimeLoginModal(!showFirstTimeLoginModal);
    };

    useEffect(() => {
        window.addEventListener("resize", handleNavBarChg);
        navigate.current = value;
        return () => {
            setValue(null);
        }
    });

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                updateLastLogin();
                getUsername(user);
            } else {
                $("#preloader").remove();
                navigate("/");
            }
            async function updateLastLogin() {
                await updateDoc(doc(db, `db_uni/${user.uid}/settings`, "account_info"), {
                    last_login: moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString()
                });
            }
        });
    });

    function toggleNavBar() {
        if (props.show) {
            document.getElementById('headerContainer').style.marginLeft = "0%";
            document.getElementById('headerContainer').style.width = "100%";
        }
        else {
            document.getElementById('headerContainer').style.marginLeft = "31%";
            document.getElementById('headerContainer').style.width = "68%";
        }
        props.onShow();
    }

    function handleNavBarChg() {
        if (window.innerWidth > 700) {
            document.getElementById('headerContainer').style.marginLeft = '0';
            document.getElementById('headerContainer').style.width = '100%';
        }
    }

    function logout() {
        auth.signOut().then(function () {
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function getUsername(user) {
        const getUName = await getDoc(doc(db, "db_uni", user.uid));
        if (getUName.exists()) {
            const data = getUName.data();
            if ((data.username).trim() === "" && !window.accessControl) {
                setShowFirstTimeLoginModal(true);
            } else {
                $("#currentUsername").text(((data.username).trim() === "") ? `.` : `${!window.accessControl ? ", " : ": "}${(data.username).toUpperCase()}.`);
            }
        }
    }

    return (
        <>
            <div className="headerContainer shadow-sm" id="headerContainer">
                <div className="headerContent">
                    <div className="path"><FaHome className="pathIcon" /> {props.path}</div>
                    <div className='headerBottom'>
                        <FontAwesomeIcon className="headerIcon" icon={faBars} id="navBarIcon" onClick={() => toggleNavBar()} />
                        <h5 className="curTitle">{props.title}</h5>
                        <div className="headerRightIcons">
                            <span className="wlcMsg">{window.accessControl ? `(${window.atob(window.accessControlUser)}) Access Control` : "Welcome back"}<span id="currentUsername">.</span> </span>
                            {!window.accessControl && <FontAwesomeIcon className="headerIcon" icon={faRightFromBracket} onClick={() => logout()} />}
                        </div>
                    </div>
                </div>
            </div>
            <FirstTimeLogin showModal={showFirstTimeLoginModal} onShow={() => firstTimeLoginHandler()} />
        </>
    );

} 