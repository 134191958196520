import React, { Component, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightFromBracket, faBell, faPlaceOfWorship } from "@fortawesome/free-solid-svg-icons";
import { FaHome } from 'react-icons/fa';

import "../css/footer.css"

export default function Footer(props) {


    return (
        <>
            <div className="footer-container">
                <div>©Copyright 2021 - {(new Date().getFullYear())}, <span onClick={() => window.open("https://highschool.my", "_blank")}>W CREATIVE STUDY PLT</span></div>
                <div>Powered by <span onClick={() => window.open("https://uhstechnology.com", "_blank")}>UHS TECHNOLOGY</span></div>
            </div>
        </>
    );

} 