import { useEffect, useState, useRef } from "react";
import "./uploadImg.css";
import {
    BsFillArrowUpCircleFill, BsTrashFill
} from "react-icons/bs";
import {
    ImPlus
} from "react-icons/im"
import ZoomImg from "../../components/zoomImage";

import { notify } from "../../components/notify";
import defaultImg from '../../assets/images/upload-placeholder.jpg';
import $ from "jquery";
import moment from 'moment-timezone';

import {
    doc,
    getDoc,
    collection,
    getDocs,
    updateDoc,
    query,
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { getStorage, ref, deleteObject, getDownloadURL, uploadString } from "firebase/storage";

export default function UploadImagesComponent(prop) {
    const [images, setImages] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [delList, setDelList] = useState([]);

    const [targetImg, setTargetImg] = useState("-1");

    const zoomImg = (url) =>{
        console.log(url);
        setTargetImg(url);
    }

    const beforeSubmission = () =>{
        let curSubmitted = prop.submitted;
        console.log('Before Submit: ' + curSubmitted);
        if(curSubmitted){
            const storage = getStorage();
    
            //clear deleted images
            let delArr = [];
            delArr = delList;
            console.log('delList: ');
            console.log(delList);
    
            if(delArr!=null){
                for(var i=0;i<delArr.length;i++){
                    let fullURL = `HighSchool_Universities/${prop.uid}/${prop.pageName}/${delArr[i]}.jpg`;
                    const desertRef = ref(storage, fullURL);
                    deleteObject(desertRef).then(() => {
                        // File deleted successfully
                        console.log('deleteSucceed: item ' + i);
                    }).catch((error) => {
                        // Uh-oh, an error occurred!
                        console.log('deleteFailed: ' + delArr[i]);
                    });
                }
            }
    
            //convert and store images to url.
            if(images == null) return;
            var allImgs = images;
            console.log(allImgs);

            var innerLoop = 0;
            for(var i = 0;i<allImgs.length;i++){
                let theFile = allImgs[i].url;
                let timestamp = allImgs[i].timestamp;

                const storageRef = ref(
                    storage,
                    `HighSchool_Universities/${prop.uid}/${prop.pageName}/${timestamp}.jpg`
                );
                const uploadTask = uploadString(storageRef, theFile, 'data_url').then((snapshot) => {
                });

                getDownloadURL(storageRef)
                    .then((url) => {
                        //allImgs[i].url = "test2";
                        console.log('DownloadURL');
                        console.log(innerLoop);
                        console.log(allImgs[innerLoop]);
                        console.log(url);

                        allImgs[innerLoop].url = url;
                        innerLoop++;
                        if(innerLoop == allImgs.length){
                            prop.onBeforeSubmit(allImgs);
                        }
                    })
                    .catch((error) => {
                        // Handle any errors
                    });
            }
            console.log(allImgs);
            //prop.onListHandler(allImgs);
            prop.onBeforeSubmit(allImgs);
        }
    }

    useEffect(() => { 
        beforeSubmission(); //cleartemp and store img to storage
    }, [prop.submitted]);

    useEffect(() => {
        setSubmitted(prop.submitted);
        setImages(prop.imgList);
        $("#imgPreviewBtn").change(function () {
            setIsUploaded(true);
            readURL(this);
        });

        return () => {
            prop.onInit();
            setImages([]);
        }
    }, []);

    function getUpdateList(arr){
        arr.map((img, index) => {
            let oriCaption = document.getElementById(`${index}-Caption`).defaultValue;
            let oriCaption_cn = document.getElementById(`${index}-Caption_cn`).defaultValue;
            let curCaption = $(`#${img.order}-Caption`).val();
            let curCaption_cn = $(`#${img.order}-Caption_cn`).val()

            //problem: caption null
            // reason: value is null, real value is in defaultValue
            //sol: id value is null, 
            if(curCaption == null || curCaption == ""){
                arr[index].caption = oriCaption;
                arr[index].caption_cn = oriCaption_cn;
            }
            else{
                arr[index].caption = curCaption;
                arr[index].caption_cn = curCaption_cn;    
            }
        });
        return arr;
    }

    function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $(`#imgPreview`).attr("src", e.target.result);
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    function swapItem(order) {
        let arr = getUpdateList(images);
        if (order === 0) {
            notify("warning", "Cannot Swap Toppest Item!", "Swap Failed");
        } else {
            arr[order].order = order - 1;
            arr[order - 1].order = order;
            setImages(JSON.parse(JSON.stringify(arr.sort((a, b) => a.order > b.order ? 1 : -1))));
        }
    }
    function deleteItem(order) {
        let arr = JSON.parse(JSON.stringify(images));
        let total = arr.length;

        let locDelList = delList;
        locDelList.push(arr[order].timestamp);
        setDelList(locDelList);

        if(total==1){
            setImages([]);
            return;
        }
        else if (order + 1 == total) {
            arr.pop();
        } else if (order >= 0) {
            for (var i = order; i < total; i++) {
                var ab = i;
                arr[i].order = i - 1;
                arr[i].url = arr[ab].url;
                arr[i].caption = arr[ab].caption;
                arr[i].caption_cn = arr[ab].caption_cn;
            }
            arr.splice(order, 1);
        }
        setImages(arr);
    }
    function uploadImg() {
        window.preloader(true);
        let caption = $("#usrInput").val();
        let caption_cn = $("#usrInput_cn").val();

        let newfilename = moment().format("YYYYMMDDHHmmss");
        let file = document.getElementById("imgPreviewBtn").files[0];

        if (file == null) {
            window.preloader(false);
            return;
        }
        else {
            let arr = images == null? [] : JSON.parse(JSON.stringify(images));
            let file = $("#imgPreview").attr("src");

            arr.push({
                order: arr.length,
                url: file,
                caption: caption,
                caption_cn: caption_cn,
                timestamp: newfilename
            });
            setImages(arr);
            window.preloader(false);
        }
        $("#imgPreview").attr("src", defaultImg);
        $("#usrInput").val("");
        $("#usrInput_cn").val("");
        setIsUploaded(false);
    }

    return (
        <div className="my-TableContainer w-100 table-responsive">
        <ZoomImg src={targetImg} onClose={()=>{setTargetImg("-1")}}/>
        <table className="table table-striped table-bordered table-hover">
            <thead className="text-center">
                <tr>
                    <th scope="col">Caption</th>
                    <th scope="col">Caption (cn)</th>
                    <th scope="col">Preview</th>
                    <th scope="col"></th>
                </tr>
            </thead>

            <tbody className="text-center">
                <tr className="text-center align-middle bg-info">
                    <td>
                        <input
                            type="text"
                            className="form-control"
                            id="usrInput"
                            disabled={!isUploaded}
                            placeholder={isUploaded ? "Write a caption for this image" : "Upload an image to add caption"}
                        ></input>
                    </td>
                    <td>
                        <input
                            type="text"
                            className="form-control"
                            id="usrInput_cn"
                            disabled={!isUploaded}
                            placeholder={isUploaded ? "为照片添加说明" : "必须上载找才能添加说明"}
                        ></input>
                    </td>
                    <td><img id="imgPreview" width="50px" height="50px" src={defaultImg} /></td>
                    <td>
                        <input className="d-none" type="file" id="imgPreviewBtn" accept="image/*" src={defaultImg}></input>
                        <label htmlFor="imgPreviewBtn" id="uploadbtn" className={isUploaded ? "d-none uploadBtn" : "uploadBtn"}>
                            Upload
                        </label>
                        <button type="button" className={isUploaded ? "btn btn-primary" : "d-none btn btn-primary"} onClick={() => uploadImg()}><ImPlus /></button>
                    </td>
                </tr>

                {images == null || images.length==0 ? (
                    <tr className="text-center align-middle">
                        <td colSpan="6">Empty</td>
                    </tr>
                ) : (
                    images.map((key, index) =>
                        <tr className="text-center align-middle" key={key.url}>
                            <td>
                                <input
                                type="text"
                                className="form-control"
                                id={index + "-Caption"}
                                defaultValue={key.caption}
                                ></input>
                            </td>
                            <td>
                                <input
                                type="text"
                                defaultValue={key.caption_cn}
                                className="form-control"
                                id={index + "-Caption_cn"}
                                ></input>    
                            </td>
                            <td><img width="50px" height="50px" src={key.url} onClick={()=>zoomImg(key.url)}/></td>
                            <td>
                                {index == 0 ? (
                                    <button
                                        type="button"
                                        className="align-center btn-sm btn-secondary"
                                        disabled
                                    >
                                        <BsFillArrowUpCircleFill className="mb-1" />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="align-center btn-sm btn-primary"
                                        onClick={() => swapItem(index, "up")}
                                    >
                                        <BsFillArrowUpCircleFill className="mb-1" />
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn-sm btn-danger"
                                    onClick={() => {
                                        deleteItem(index);
                                    }}
                                >
                                    <BsTrashFill />
                                </button>
                            </td>
                        </tr>
                    )
                )}       
            </tbody>
        </table>
    </div>

    )
}