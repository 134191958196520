import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/profile.css";
import Modal from "../../components/modal-container";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { useEffect, useState, useRef } from "react";
import defaultImg from '../../assets/images/upload-placeholder.jpg';

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
  updateDoc,
  FieldPath,
} from "firebase/firestore";
import { storage, auth, db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function ImagesPage(props) {
  const [user] = useAuthState(auth);

  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  const [imgId, setImgId] = useState("");
  const [images, setImages] = useState([]);
  const [curImage, setCurImages] = useState({});
  let count = 0;

  function readURL(input, type) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $(`#imgPreview`).attr("src", e.target.result)
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/gallery`),
          where("order", "!=", -1),
          orderBy("order")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setImages([]);
          querySnapshot.forEach((doc) => {
            setImages((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });

        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/gallery`),
            where("order", "!=", -1),
            orderBy("order")
          );
          let data = await getDocs(q);
          setImages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          window.preloader(false);
        };
        getDbData();

        return () => {
          unsub();
        };
      }
    });
  }, []);

  const deleteItem = async (tImgId) => {
    //prompt delete or not.
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/deleteImage",
      data: {
        uid: user.uid,
        imgId: tImgId,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", `1 Image is Deleted!`, "Deleted Successfully");
        } else {
          action == "New"
            ? notify("error", "Deletion Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  };

  const popupModal = (id) => {
    if (id == "") {
      setCurImages({});
      setAction("New");
      setImgId("");
    } else {
      setAction("Edit");
      setImgId(id);
      getImgDetail(id);
    }
    setShowModal(true);
  };

  const getImgDetail = (id) => {
    setCurImages(images.find((item) => item.id == id));
  };

  async function UploadImg(id) {
    window.preloader(true);
    let theFile = document.getElementById("uploadBtnId").files[0];

    const storageRef = ref(
      storage,
      `HighSchool_Universities/${user.uid}/gallery/${id}.jpg`
    );
    const uploadTask = uploadBytesResumable(storageRef, theFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => { },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          const updateURL = async () => {
            const tDoc = doc(
              db,
              `db_uni/${user.uid}/gallery`,
              id
            );
            const updatedField = { imageURL: url };
            await updateDoc(tDoc, updatedField);
          };
          updateURL();
          notify("success", "Image is uploaded successfully", "Image Updated");

          setShowModal(!showModal);
        });
      }
    );
    window.preloader(false);
  }

  const save = async (imgId) => {
    window.preloader(true);
    imgId == "New" ? (imgId = "") : (imgId = imgId);
    let caption = $("#caption").val();
    let caption_cn = $("#caption_cn").val();
    let imgID = $("imgId").val();
    let sort = $("sort").val();
    let publish = $("#publish").prop("checked");
    let uploadedFile = document.getElementById("uploadBtnId").files[0];
    let order = $("#order").val();
    //Checking may needed
    let theSrc = $('#imgPreview').prop('src');

    if (uploadedFile == null && (theSrc.includes("upload-placeholder.65c08d093ed42e5f9611.jpg") || theSrc == null)) {
      notify('warning', 'Image is missing. Please upload an image!.', "Image is missing");
      window.preloader(false);
      return;
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyImage",
      data: {
        action: action,
        uid: user.uid,
        imgId: imgId,
        caption: caption,
        caption_cn: caption_cn,
        order: order,
        publish: publish,
      },
      success: function (msgInput) {
        let msg = msgInput.split(":")[0];
        let id = msgInput.split(":")[1];

        if (msg === "1") {
          notify("success", "Image is added!", "Added Successfully");
        } else if (msg === "2") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          action == "New"
            ? notify("error", "Creation Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");

          window.preloader(false);
          return;
        }

        if (uploadedFile == null) {
          setShowModal(!showModal);
        }
        else {
          UploadImg(id);
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(imgId);
        }}
        action={action}
        title="Image"
      >
        <div className="form-group mt-1">
          <input type="hidden" id="order" defaultValue={action == "New" ? images.length : curImage.order}></input>
          <label htmlFor="caption" className="my-FormLabel">
            Caption
          </label>
          <input
            type="text"
            className="form-control"
            id="caption"
            defaultValue={curImage.caption}
            placeholder="Add Caption for this image"
          ></input>
        </div>

        <div className="form-group mt-1">
          <label htmlFor="caption_cn" className="my-FormLabel">
            Caption (CN)
          </label>
          <input
            type="text"
            className="form-control"
            id="caption_cn"
            defaultValue={curImage.caption_cn}
            placeholder="为照片添加说明"
          ></input>
        </div>

        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={curImage.publish}
          ></input>
        </div>

        <div className="form-group mt-1 w-100">
          <label htmlFor="image" className="my-FormLabel">
            Image
          </label><br />
          <span className="btn btn-default btn-file w-100">
            <input type="file" className="form-control" id="uploadBtnId" accept="image/*" onChange={(event) => {
              readURL(event.target, "uploadBtnId");
            }}></input>
          </span>
        </div>

        <div className="form-group mt-1">
          <div className="text-center"><img id="imgPreview" style={{ width: "30vw", height: "30vw" }} src={curImage.imageURL == null ? defaultImg : curImage.imageURL}></img></div>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Images(s)</span>
        </div>

        <form className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addImages"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Images
            </button>
          </div>

          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr className="text-center">
                  <th scope="col"></th>
                  <th scope="col">Caption</th>
                  <th scope="col">Caption(CN)</th>
                  <th scope="col">Preview</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {images.length == 0 ? (
                  <tr>
                    <td colSpan="9">Empty</td>
                  </tr>
                ) : (
                  images.map((img) => {
                    count = count + 1;
                    return (
                      <tr key={count} className="align-middle">
                        <th scope="row">{count}</th>
                        <td>{img.caption}</td>
                        <td>{img.caption_cn}</td>
                        <td width="30px" height="30px"><img className="w-100" src={img.imageURL} alt="" /></td>
                        <td>{img.publish ? "Active" : "Inactive"}</td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(img.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              deleteItem(img.id);
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
