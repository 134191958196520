import { useState } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import { auth, db } from "./../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import CryptoJS from 'crypto-js';
import { notify } from "../../../components/notify";
import "../../../css/style.css";

export default function FirstTimeLogin(props) {
    const [user] = useAuthState(auth);
    const [usernameFT, setUsernameFT] = useState("");
    const [passwordNFT, setPasswordNFT] = useState("");
    const [passwordRFT, setPasswordRFT] = useState("");
    const [allowFTUNUpdate, setAllowFTUNUpdate] = useState(false);
    const [allowFTNPWUpdate, setAllowFTNPWUpdate] = useState(false);
    const [allowFTRPWUpdate, setAllowFTRPWUpdate] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const FTAPI = "http://localhost:4220/highschool-web/asia-southeast1/uniPortalFTSetup";

    function updateFTLogin() {
        if (allowFTUNUpdate && allowFTNPWUpdate && allowFTRPWUpdate) {
            const enc_pw = CryptoJS.AES.encrypt(document.getElementById("ftst-npw").value, "unipw").toString();
            window.preloader(true);
            $.ajax({
                type: 'POST',
                url: FTAPI,
                data: { "uid": user.uid, "passphrase": window.btoa(document.getElementById("ftst-npw").value), "passphrase_e": enc_pw, "uname": window.btoa(document.getElementById("ftst-username").value) },
                success: function (msg) {
                    if (msg.indexOf('success') > -1) {
                        window.preloader(false);
                        document.getElementById("currentUsername").textContent = `, ${(document.getElementById("ftst-username").value).toUpperCase()}`;
                        notify("success", "Your account has been setup successfully.", "First Time Login");
                        props.onShow();
                    } else if (msg.indexOf('error') > -1) {
                        window.preloader(false);
                        notify("error", "Internal Server Error. Please try again or contact IT Support.", "Code 503");
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    window.preloader(false);
                    notify("error", "Internal Server Error. Please try again or contact IT Support.", "Code 503");
                }
            });
        } else {
            notify("error", "Unauthorised Action.", "Action Aborted");
        }
    };

    async function checkUsernameAvailability(evt) {
        setUsernameFT(evt.target.value.toUpperCase());
        const value = evt.target.value.toLowerCase().trim();
        if (value === "") {
            $(".ft-table tr:first-child td>span").text("Username must not be empty.");
            $(".ft-table tr:first-child td>span").css("color", "red");
            $(".ft-table tr:first-child td>span").css("display", "block");
            setDisabled(true); setAllowFTUNUpdate(false);
        } else if (!value.match(/^[a-z0-9]+$/i)) {
            $(".ft-table tr:first-child td>span").text("Username can only contains alphanumeric.");
            $(".ft-table tr:first-child td>span").css("color", "red");
            $(".ft-table tr:first-child td>span").css("display", "block");
            setDisabled(true); setAllowFTUNUpdate(false);
        } else if (value.length < 5) {
            $(".ft-table tr:first-child td>span").text("Username must at least 5 characters long.");
            $(".ft-table tr:first-child td>span").css("color", "red");
            $(".ft-table tr:first-child td>span").css("display", "block");
            setDisabled(true); setAllowFTUNUpdate(false);
        } else {
            let dup = false;
            const qs = await getDocs(query(collection(db, "db_uni"), where("username", "==", value)));
            qs.forEach((doc) => {
                dup = true;
            });
            if (dup) {
                $(".ft-table tr:first-child td>span").text("Username has been taken.");
                $(".ft-table tr:first-child td>span").css("color", "red");
                $(".ft-table tr:first-child td>span").css("display", "block");
                setDisabled(true); setAllowFTUNUpdate(false);
            } else {
                $(".ft-table tr:first-child td>span").text("Username available.");
                $(".ft-table tr:first-child td>span").css("color", "green");
                $(".ft-table tr:first-child td>span").css("display", "block");
                setAllowFTUNUpdate(true);
                if (allowFTNPWUpdate && allowFTRPWUpdate) {
                    setDisabled(false);
                }
            }
        }
    };

    async function checkNewPasswordFormat(evt) {
        setPasswordNFT(evt.target.value);
        const value = evt.target.value;
        if (value.length < 5) {
            $(".ft-table tr:nth-child(2) td>span").text("Password must at least 5 characters long.");
            $(".ft-table tr:nth-child(2) td>span").css("color", "red");
            $(".ft-table tr:nth-child(2) td>span").css("display", "block");
            setDisabled(true); setAllowFTNPWUpdate(false);
        } else {
            $(".ft-table tr:nth-child(2) td>span").text("");
            $(".ft-table tr:nth-child(2) td>span").css("display", "none");
            setAllowFTNPWUpdate(true);
            if (allowFTUNUpdate && allowFTRPWUpdate) {
                setDisabled(false);
            } else if (!allowFTRPWUpdate) {
                if (value === $("#ftst-rnpw").val()) {
                    $(".ft-table tr:nth-child(3) td>span").text("");
                    $(".ft-table tr:nth-child(3) td>span").css("display", "none");
                    setAllowFTRPWUpdate(true);
                    setDisabled(false);
                }
            }
        }
    };

    async function checkRptPasswordFormat(evt) {
        setPasswordRFT(evt.target.value);
        const value = evt.target.value;
        if (value === $("#ftst-npw").val()) {
            $(".ft-table tr:nth-child(3) td>span").text("");
            $(".ft-table tr:nth-child(3) td>span").css("display", "none");
            setAllowFTRPWUpdate(true);
            if (allowFTUNUpdate && allowFTNPWUpdate) {
                setDisabled(false);
            }
        } else {
            $(".ft-table tr:nth-child(3) td>span").text("Password does not match.");
            $(".ft-table tr:nth-child(3) td>span").css("color", "red");
            $(".ft-table tr:nth-child(3) td>span").css("display", "block");
            setDisabled(true); setAllowFTRPWUpdate(false);
        }
    };

    return (
        <Modal id="ftsetupModal" backdrop="static" centered show={props.showModal} onHide={() => props.onShow()} className="noselect">
            <Modal.Header><h5 className="modal-title">First Time Login</h5></Modal.Header>
            <Modal.Body>
                <table className="ft-table">
                    <tbody>
                        <tr>
                            <td>Username:</td>
                            <td>
                                <input type="text" className="form-control" id="ftst-username" placeholder="Username" value={usernameFT} onChange={checkUsernameAvailability} />
                                <span></span>
                            </td>
                        </tr>
                        <tr>
                            <td>New Password:</td>
                            <td>
                                <input type="password" className="form-control" id="ftst-npw" placeholder="New Password" value={passwordNFT} onChange={checkNewPasswordFormat} />
                                <span></span>
                            </td>
                        </tr>
                        <tr>
                            <td>Confirm Password:</td>
                            <td>
                                <input type="password" className="form-control" id="ftst-rnpw" placeholder="Repeat New Password" value={passwordRFT} onChange={checkRptPasswordFormat} />
                                <span></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" id="ftLoginBtn" onClick={() => updateFTLogin()} disabled={isDisabled}>Update Profile</button>
            </Modal.Footer>
        </Modal>
    );
}