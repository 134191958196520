import { Modal } from "react-bootstrap";

export default function PopupMsg(props) {
    return (
        <Modal
            show={props.showModal}
            onHide={() => props.onShow()}
            style={{ marginTop: "12vw" }}
        >
            <Modal.Header closeButton><h5 className="modal-title">Login Error</h5></Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: props.message }}>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={() => props.onShow()}>Close</button>
            </Modal.Footer>

        </Modal>
    );
}