import ComingSoon from "./pages/Comingsoon";
import Dashboard from "./pages/Dashboard";
import Information from "./pages/information/Information";
import Profile from "./pages/information/Profile";
import Introduction from "./pages/information/Introduction";
import Service from "./pages/information/Service";
import Achievement from "./pages/information/Achievement";
import Specialty from "./pages/information/Specialty";
import FinancialAid from "./pages/information/FinancialAid";
import Living from "./pages/information/Living";
import Facility from "./pages/information/Facility";

import Programme from "./pages/programme/Programme";
import Campus from "./pages/campus/Campus";
import Hostel from "./pages/hostel/Hostel";
import Inquiry from "./pages/inquiry/Inquiry"

import Settings from "./pages/settings/Settings";
import LoginSetting from "./pages/settings/LoginSetting";

import Media from "./pages/media/Media";
import Gallery from "./pages/media/Gallery";
import Video from "./pages/media/Video";

import { BsFillBarChartLineFill, BsFillInfoCircleFill } from "react-icons/bs";
import {
  RiComputerLine,
  RiUserSearchFill,
  RiUserSettingsFill,
  RiSettings4Fill,
} from "react-icons/ri";
import {
  MdOutlineFormatQuote,
  MdBuild,
  MdLocationOn,
  MdLiving,
  MdOutlinePermMedia,
} from "react-icons/md";
import {
  FaTrophy,
  FaBook,
  FaUniversity,
  FaBuilding,
  FaImage,
  FaFileVideo,
  FaPhotoVideo,
} from "react-icons/fa";
import { GrGallery } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import { AiFillMoneyCollect } from "react-icons/ai";
import { ImHome3 } from "react-icons/im";
import { BiIdCard } from "react-icons/bi";
import { IoMdAnalytics } from "react-icons/io";

const routes = [
  {
    type: "primary",
    name: "Dashboard",
    key: "dashboard",
    icon: <BsFillBarChartLineFill className="navBarIcon" />,
    route: "/Dashboard",
    component: <Dashboard />,
  },
  {
    type: "primary",
    name: "Information",
    key: "information",
    icon: <FaUniversity className="navBarIcon" />,
    route: "/Information",
    component: <Information />,
  },
  {
    type: "primary",
    name: "Campus",
    key: "campus",
    icon: <MdLocationOn className="navBarIcon" />,
    route: "/Campus",
    component: <Campus />,
  },
  {
    type: "primary",
    name: "Programme",
    key: "programme",
    icon: <FaBook className="navBarIcon" />,
    route: "/Programme",
    component: <Programme />,
  },
  {
    type: "primary",
    name: "Hostel",
    key: "hostel",
    icon: <ImHome3 className="navBarIcon" />,
    route: "/Hostel",
    component: <Hostel />,
  },
  {
    type: "primary",
    name: "Media",
    key: "media",
    icon: <FaPhotoVideo className="navBarIcon" />,
    route: "/Media",
    component: <Media />,
  },
  {
    type: "primary",
    name: "Inquiry",
    key: "inquiry",
    icon: <RiUserSearchFill className="navBarIcon" />,
    route: "/Inquiry",
    component: <Inquiry />,
  },
  {
    type: "primary",
    name: "Settings",
    key: "settings",
    icon: <RiUserSettingsFill className="navBarIcon" />,
    route: "/Settings",
    component: <Settings />,
  },
  {
    type: "primarySpecial",
    name: "Account Setting",
    key: "Account setting",
    icon: <RiSettings4Fill className="navBarIcon" />,
    route: "/Account-Setting",
    component: <LoginSetting />,
  },
  //SECONDARY
  //Dashboard
  //Information
  {
    type: "secondary",
    name: "Profile",
    key: "profile",
    icon: <FaUniversity className="itemIcon" />,
    route: "/Information/Profile",
    component: <Profile />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Introduction",
    key: "introduction",
    icon: <BsFillInfoCircleFill className="itemIcon" />,
    route: "/Information/Introduction",
    component: <Introduction />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Service",
    key: "service",
    icon: <MdBuild className="itemIcon" />,
    route: "/Information/Service",
    component: <Service />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Achievement",
    key: "achievement",
    icon: <FaTrophy className="itemIcon" />,
    route: "/Information/Achievement",
    component: <Achievement />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Specialty",
    key: "specialty",
    icon: <TiTick className="itemIcon" />,
    route: "/Information/Specialty",
    component: <Specialty />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Financial Aid",
    key: "financial-aid",
    icon: <AiFillMoneyCollect className="itemIcon" />,
    route: "/Information/Financial-Aid",
    component: <FinancialAid />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Living",
    key: "living",
    icon: <MdLiving className="itemIcon" />,
    route: "/Information/Living",
    component: <Living />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Facility",
    key: "facility",
    icon: <RiComputerLine className="itemIcon" />,
    route: "/Information/Facility",
    component: <Facility />,
    parentRoute: "/Information",
  },
  {
    type: "secondary",
    name: "Gallery",
    key: "gallery",
    icon: <FaImage className="itemIcon" />,
    route: "/Media/Gallery",
    component: <Gallery />,
    parentRoute: "/Media",
  },
  {
    type: "secondary",
    name: "Video",
    key: "video",
    icon: <FaFileVideo className="itemIcon" />,
    route: "/Media/Video",
    component: <Video />,
    parentRoute: "/Media",
  },
];

export default routes;
