import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/profile.css";
import Modal from "../../components/modal-container";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { useEffect, useState, useRef } from "react";

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";

export default function FinancialAidPage(props) {
  const [user] = useAuthState(auth);

  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  const [curId, setCurId] = useState("");
  const [FA, setFA] = useState([]);
  const [curFA, setCurFA] = useState({});

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/financial_aid`),
          where("sort", "!=", "-1"),
          orderBy("sort")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setFA([]);
          querySnapshot.forEach((doc) => {
            setFA((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });

        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/financial_aid`),
            where("sort", "!=", "-1"),
            orderBy("sort")
          );
          let data = await getDocs(q);
          setFA(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          window.preloader(false);
        };
        getDbData();

        return () => {
          unsub();
          setShowModal(false);
          setCurId("");
          setFA("");
          setCurFA("");
        };
      }
    });
  }, []);

  const deleteItem = async (tcurId) => {
    //prompt delete or not.
    const getUIntro = await deleteDoc(
      doc(db, `db_uni/${user.uid}/financial_aid`, tcurId)
    );
  };

  const popupModal = (id) => {
    if (id == "") {
      setCurFA({});
      setAction("New");
      setCurId("");
    } else {
      setAction("Edit");
      setCurId(id);
      getFADetail(id);
    }
    setShowModal(true);
  };

  const getFADetail = (id) => {
    setCurFA(FA.find((item) => item.id == id));
  };

  const save = async (curId) => {
    window.preloader(true);
    curId == "New" ? (curId = "") : (curId = curId);
    let title = $("#title").val();
    let title_cn = $("#title_cn").val();
    let benefits = $("#benefits").val();
    let benefits_cn = $("#benefits_cn").val();
    let condition = $("#condition").val();
    let condition_cn = $("#condition_cn").val();
    let method = $("#method").val();
    let method_cn = $("#method_cn").val();
    let availability = $("#availability").val();
    let availability_cn = $("#availability_cn").val();
    let publish = $("#publish").prop("checked");

    let sort = $("#sort").val();

    //Checking may needed
    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyFinancialAid",
      data: {
        action: action,
        uid: user.uid,
        faId: curId,
        title: title,
        title_cn: title_cn,
        sort: sort,
        benefits: benefits,
        benefits_cn: benefits_cn,
        condition: condition,
        condition_cn: condition_cn,
        method: method,
        method_cn: method_cn,
        availability: availability,
        availability_cn: availability_cn,
        publish: publish,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "New Campus is created!", "Created Successfully");
        } else if (msg === "2") {
          notify("success", "Change is updated!", "Updated Successfully");
        } else {
          action == "New"
            ? notify("error", "Creation Failed. Please contact admin", "Error")
            : notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
    setShowModal(!showModal);
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(curId);
        }}
        action={action}
        title="Financial Aid"
      >
        <input
          type="hidden"
          id="sort"
          value={action == "New" ? FA.length : curFA.sort}
        ></input>
        <div className="form-group mt-1">
          <label htmlFor="title" className="my-FormLabel">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            defaultValue={curFA.title}
            placeholder=" e.g. Tunku Abdul Rahman University College Merit Scholarship"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="title_cn" className="my-FormLabel">
            Title (in Chinese) / 名称
          </label>
          <input
            type="text"
            className="form-control"
            id="title_cn"
            defaultValue={curFA.title_cn}
            placeholder="例：国立暨南国际大学侨生奖学金"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="benefits" className="my-FormLabel">
            Benefits
          </label>
          <textarea
            className="form-control"
            rows="6"
            defaultValue={curFA.benefits}
            placeholder="Please list in point form the benefits offered by the relevant scholarships and study loans (no limit on the number of such benefits, but preferably not more than 5 items)"
            id="benefits"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="benefits_cn" className="my-FormLabel">
            Benefits (cn) / 优惠
          </label>
          <textarea
            className="form-control"
            defaultValue={curFA.benefits_cn}
            rows="6"
            placeholder="请以重点形式（point form）列明有关奖贷学金的优惠（数量不拘，但建议不超过5项。）"
            id="benefits_cn"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="condition" className="my-FormLabel">
            Conditions of application
          </label>
          <textarea
            className="form-control"
            rows="6"
            defaultValue={curFA.condition}
            placeholder="Please list in point form the conditions of application for the relevant scholarships and study loans (no limit on the number of such conditions, but preferably not more than 5 items)"
            id="condition"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="condition_cn" className="my-FormLabel">
            Conditions of application (cn) / 申请条件
          </label>
          <textarea
            className="form-control"
            rows="6"
            defaultValue={curFA.condition_cn}
            placeholder="请以重点形式（point form）列明有关奖贷学金的申请条件（数量不拘，但建议不超过5项。）"
            id="condition_cn"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="method" className="my-FormLabel">
            Application method
          </label>
          <textarea
            className="form-control"
            rows="6"
            defaultValue={curFA.method}
            placeholder="Please list in point form the application method for the relevant scholarships and study loans"
            id="method"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="method_cn" className="my-FormLabel">
            Application method (cn) / 申请方式
          </label>
          <textarea
            className="form-control"
            rows="6"
            defaultValue={curFA.method_cn}
            placeholder="请以重点形式（point form）列明有关奖贷学金的申请方式"
            id="method_cn"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="availability" className="my-FormLabel">
            Availability
          </label>
          <input
            type="text"
            placeholder="Please indicate the availability for the relevant scholarships and study loans"
            className="form-control"
            id="availability"
            defaultValue={curFA.availability}
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="availability_cn" className="my-FormLabel">
            Availability(cn) / 名额
          </label>
          <input
            type="text"
            placeholder="Please indicate the availability for the relevant scholarships and study loans"
            className="form-control"
            id="availability_cn"
            defaultValue={curFA.availability_cn}
          ></input>
        </div>

        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={curFA.publish}
          ></input>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Financial Aid(s)</span>
        </div>

        <form className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addFA"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Financial Aid
            </button>
          </div>

          <div className="my-TableContainer">
            <table className="table table-striped table-bordered table-hover table-responsive">
              <thead>
                <tr className="text-center">
                  <th scope="col">Title</th>
                  <th scope="col">Title(cn)</th>
                  <th scope="col">Sort</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {FA.length == 0 ? (
                  <tr>
                    <td colSpan="9">Empty</td>
                  </tr>
                ) : (
                  FA.map((fa) => {
                    return (
                      <tr key={fa.sort} className="align-middle">
                        <td>{fa.title}</td>
                        <td>{fa.title_cn}</td>
                        <td>{Number.parseInt(fa.sort) + 1}</td>
                        <td>{fa.publish ? "Active" : "Inactive"}</td>
                        <td>
                          <button
                            type="button"
                            className="btn-sm btn-primary me-1 mb-1"
                            onClick={() => {
                              popupModal(fa.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              deleteItem(fa.id);
                            }}
                          >
                            <BsTrashFill />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </LoggedInLayout>
  );
}
