import LoggedInLayout from "../../layouts/loggedInLayout";
import { IoIosSave } from "react-icons/io";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import Modal from "../../components/modal-container";

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function ServicePage(props) {
  const [user, loading, error] = useAuthState(auth);
  const [services, setServices] = useState([]);

  const [curId, setCurId] = useState("");
  const [cur, setCur] = useState({});
  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);
  let count = 0;

  async function getDbData(uid) {
    const q = query(collection(db, `db_uni/${uid}/service`));
    let data = await getDocs(q);
    setServices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }
  
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/service`),
          where("created_on", "!=","placeholder"),
          orderBy("created_on")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setServices([]);
          querySnapshot.forEach((doc) => {
            setServices((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });
  
        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/service`),
            where("created_on", "!=", "placeholder"),
            orderBy("created_on")
          );
          let data = await getDocs(q);
          setServices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          window.preloader(false);
        };
        getDbData();
      }
    });

    return () => {
      setServices([]);
      //unsub();
    };
  }, []);

  function save() {
    window.preloader(true);
    let name = $("#name").val();
    let name_cn = $("#name_cn").val();
    let desc = $("#desc").val();
    let desc_cn = $("#desc_cn").val();

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyService",
      data: {
        action: action,
        uid: user.uid,
        serviceId: curId,
        name: name,
        name_cn: name_cn,
        desc: desc,
        desc_cn: desc_cn,
      },
      success: function (msg) {
        if (msg === "1") {
          alert(`New service is added Successfully! Kindly check after 5 mins if change is not visible.`);
          notify("success", "New Service is Created!", "Created Successfully");
          setShowModal(false);
        } 
        else if(msg === "2"){
          notify("success", "Change is updated!", "Updated Successfully");
          setShowModal(false);
        }else {
          notify("error", "ID not found. Please contact admin.", "Error");
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  const deleteItem = async (tId) => {
    //prompt delete or not.
    const getUIntro = await deleteDoc(
      doc(db, `db_uni/${user.uid}/service`, tId)
    );
    notify("success", `"${cur.name}" is Deleted!`, "Deleted Successfully");
    setShowModal(false);
  };

  const popupModal = (id, uid) => {
    setCur({});
    setCurId("");

    if (id == "") {
      setAction("New");
    }
    else if(id=="Delete"){
      setAction("Delete");
      var localCur = services.find((item) => item.id == uid);
      setCur(localCur);
      setShowModal(true);
    }
    else {
      setAction("Edit");
      setCurId(id);
      getDetail(id);
    }
    setShowModal(true);
  }

  const getDetail = (id) => {
    setCur(services.find((item) => item.id == id));
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(cur.id);
        }}
        onDelete={() => {
          deleteItem(cur.id);
        }}
        itemName={cur.name}
        action={action}
        title="Service"
      >
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Service Title
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            defaultValue={cur.name}
            placeholder="E.g: School Bus Service"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Service Title (cn)
          </label>
          <input
            type="text"
            className="form-control"
            id="name_cn"
            defaultValue={cur.name_cn}
            placeholder="例:　校车服务"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Description
          </label>
          <textarea
            type="text"
            className="form-control"
            id="desc"
            defaultValue={cur.desc}
            placeholder="Introduce this service in not more than 350 characters such as types, numbers, activity contents etc. Example: More than 30 student organizations of different categories including academics, skills, sports, music, social services etc."
            rows="5"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="name" className="my-FormLabel">
            Description (CN)
          </label>
          <textarea
            type="text"
            className="form-control"
            id="desc_cn"
            defaultValue={cur.desc_cn}
            placeholder="请以不超过100字简介，如种类、数量、活动内容等。例：30多个学生社团，包括学术类、才艺类、体育类、音乐类、社会服务类等等。"
            rows="5"
          ></textarea>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Edit Service(s)</span>
        </div>

        <div className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addService"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Service
            </button>
          </div>
          <div className="my-TableContainer table-responsive">
            <table className="table table-striped table-bordered table-hover commonTable">
              <thead>
                <tr className="text-center align-middle">
                  <th scope="col" width="5%">#</th>
                  <th scope="col" width="19%">Title</th>
                  <th scope="col" width="33%">Description</th>
                  <th scope="col" width="33%">Description (cn)</th>
                  <th scope="col" width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {services.map((service, index) => {                              
                  return (
                    <tr className="text-center align-middle" key={index+1}>
                      <td>{index+1}</td>
                      <td>{service.name}<br/><i className="text-muted">{service.name_cn}</i></td>
                      <td>{service.desc.length > 75? `${service.desc.substring(0,73)}...`: service.desc}</td>
                      <td>{service.desc_cn.length > 40? `${service.desc_cn.substring(0,38)}...`: service.desc_cn}</td>
                      <td>
                        <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(service.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal('Delete', service.id);
                            }}
                          >
                            <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  );              
                })}
              </tbody>
            </table>
          </div>  
        </div>
      </div>
    </LoggedInLayout>
  );
}
