import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
  putString
} from "firebase/storage";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  onSnapshot,
  getFirestore,
  query,
  getDocs,
  getDoc,
  updateDoc,
  collection,
  where,
  addDoc,
  deleteDoc,
  FieldPath,
  orderBy
} from "firebase/firestore";
import { getDatabase, ref as ref_database, onValue } from "firebase/database";
const crypto_key = window.atob("VWhzXkhpZ2hzY2hvb2w=");
const firebaseConfig = {
  apiKey: "AIzaSyAdbU1A0nCrN5wJZutOgL8sVuFrzH-deUo",
  authDomain: "highschool-web.firebaseapp.com",
  projectId: "highschool-web",
  storageBucket: "highschool-web.appspot.com",
  messagingSenderId: "723932732600",
  appId: "1:723932732600:web:49934bacfa4e78af96a84d",
  databaseURL: "https://highschool-chatroom.asia-southeast1.firebasedatabase.app",
  measurementId: "G-3NE524FQE9",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const rtdb = getDatabase(app);
