import LoggedInLayout from "../../layouts/loggedInLayout";
import routes from "../../routes";
import { useLocation, Link } from "react-router-dom";

export default function InformationPage(props) {
  let location = useLocation().pathname;

  const renderMenuItem = routes.map((route) => {
    let returnValue;
    if (route.type == "secondary" && route.parentRoute == location) {
      returnValue = (
        <Link key={route.name} className="item" to={route.route}>
          <div>{route.icon}</div>
          <div className="itemName">{route.name}</div>
        </Link>
      );
    }
    return returnValue;
  });

  return (
    <LoggedInLayout>
      <div
        className="container contentContainer"
        style={{ backgroundColor: "#f0f2f5" }}
      >
        {renderMenuItem}
      </div>
    </LoggedInLayout>
  );
}
