import SideNav from "../components/sideNav";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/sideNav.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useEffect, useState } from "react";
import { unmountComponentAtNode } from "react-dom";
import "../css/layouts/loggedInLayout.css"
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../routes";

export default function LoggedInLayout(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [navBarStatus, setNavBarStatus] = useState(false);
    const [title, setTitle] = useState('');
    const [path, setPath] = useState('');
    const [backBtn, setBackBtn] = useState(false);

    useEffect(() => {
        detectWidth();
        window.addEventListener("resize", detectWidth);
        location.pathname.split('/').length > 2 ? setBackBtn(true) : setBackBtn(false);

        return () => {
            setNavBarStatus(false);
            setTitle("");
            setBackBtn(false);
            setPath("");
        }
    }, []);

    useEffect(() => {
        routes.map((route) => {
            location.pathname == route.route && setTitle(route.name);
        });

        let path = location.pathname.replaceAll('/', ' / ');
        path = path.replace('-', ' ');
        setPath(path);

    }, [location.pathname]);

    function navBarHandler() {
        setNavBarStatus(!navBarStatus);
    }

    function detectWidth() {
        window.innerWidth <= 700 ?
            document.getElementById('content').style.paddingLeft = "0" :
            document.getElementById('content').style.paddingLeft = "20%";
    }

    return (
        <div className="loggedInContainer">
            <SideNav show={navBarStatus} />

            <div className="content" id="content">
                <Header
                    onShow={() => navBarHandler()}
                    show={navBarStatus}
                    title={title}
                    path={path} />
                {backBtn ? <button className="btn btn-primary mb-1" onClick={() => { navigate(-1) }}>Back</button> : ""}
                {props.children}
                <Footer />
            </div>
            <NotificationContainer />
        </div>
    );
}