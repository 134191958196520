import { Routes, Route } from "react-router-dom";
import SignInPage from "./pages/auth/SignIn";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/theme.css";
import routes from "./routes";
import $ from "jquery";

function App() {
  //Global Functions and Variables;
  window.accessControl = false;
  window.accessControlUser = null;
  window.preloader = (bool) => {
    const preloaderPath = require("./assets/images/apple-icon.png");
    if (bool) {
      const preloader = document.createElement('section');
      preloader.setAttribute("class", "section-preloader semi");
      preloader.setAttribute("id", "preloader");
      preloader.innerHTML = `<div class="preloader"><img src="${preloaderPath}" /></div>`;
      document.body.appendChild(preloader);
    } else {
      $("#preloader").remove();
    }
  };

  //Routing
  const AllRoutes = routes.map((route) => {
    return <Route exact path={route.route} key={route.key} element={route.component} />;
  });

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<SignInPage />} />
        {AllRoutes}
      </Routes>
    </div>
  );
}

export default App;
