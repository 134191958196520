import LoggedInLayout from "../../layouts/loggedInLayout";
import { BsChatDotsFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import Modal from "../../components/modal-container";
import defaultImg from '../../assets/images/upload-placeholder.jpg';
import { Tabs, Tab, Badge } from "react-bootstrap";
import moment from 'moment-timezone'
import "./chatRoom.css"

import ChatRoom from "./chatRoomModal";

import {
    doc,
    collection,
    getDocs,
    onSnapshot,
    query,
    where,
    deleteDoc,
    orderBy,
    updateDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, getStorage } from "firebase/storage";
import { storage, auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function InquiryPage(props) {
    const [user, loading, error] = useAuthState(auth);
    const [userId, setUserId] = useState("");
    const [key, setKey] = useState('all');

    const [inquiries, setInquiries] = useState([]);
    const [newInq, setNewInq] = useState([]);
    const [seenInq, setSeenInq] = useState([]);

    const [cur, setCur] = useState({});
    const [showModal, setShowModal] = useState(false);
    let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();

    useEffect(() => {
        console.log('ouyt');
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                window.scrollTo(0, 0);
                const q = query(
                    collection(db, `db_uni/${user.uid}/inquiry`),
                    where("created_on", "!=", "placeholder"),
                    orderBy("created_on")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                    var chatbox = document.getElementById('chat-container');
                    if (chatbox != null) {
                        chatbox.scrollTop = chatbox.scrollHeight;
                    }

                    setInquiries([]); setNewInq([]); setSeenInq([]);
                    let localInqs = [];

                    querySnapshot.forEach((doc) => {
                        localInqs.push({ ...doc.data(), id: doc.id });
                    });

                    curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
                    setInquiries(localInqs);
                    setNewInq(localInqs.filter(x => x.flag == "open"));
                    setSeenInq(localInqs.filter(x => x.flag == "close"));
                });
            }
        });

        return () => {
            setInquiries([]);
            //unsub();
        };
    }, []);

    function changeTab(evt) {
        setKey(evt);
    }
    function convertDate(inp, type) {
        //20220215 1315 15
        //20220215 1315
        let ts = moment(inp, "YYYYMMDDHHmmss").fromNow();
        let curDate = curTS.substring(0, 8);
        let inpDate = inp.substring(0, 8);
        let curHr = curTS.substring(8, 10);
        let inpHr = inp.substring(8, 10);
        let curMin = curTS.substring(10, 12);
        let inpMin = inp.substring(10, 12);

        if (curDate != inpDate) {
            return moment(inp, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm");
        }
        else {
            if (curHr != inpHr) {
                let a = (curHr - inpHr).toString();
                a += (curMin - inpMin) == 1 ? " hours ago" : " hours ago"
                return a;
            }
            else if (curMin == inpMin) {
                return "Just now";
            }
            else {
                let a = (curMin - inpMin).toString();
                a += (curMin - inpMin) == 1 ? " minute ago" : " minutes ago"
                return a;
            }
        }
    }

    const popupModal = (type, id) => {
        setCur({});
        setCur(inquiries.find((item) => item.id == id));
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);

    }

    return (
        <LoggedInLayout>
            <ChatRoom showModal={showModal} onShow={() => setShowModal(false)} uid={userId} targetInfo={cur} />

            <div className="contentContainer" id="settings-0">
                <div className="contentTitle position-relative">
                    <span>Manage Inquiry(s)</span>
                </div>

                <div className="contentBody">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => changeTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="unread" title={<><b>Unsolved</b><Badge className="ms-1" bg="danger">{newInq.length}</Badge></>}>
                            <div className="my-TableContainer table-responsive">
                                <table className="table table-striped table-bordered table-hover commonTable">
                                    <thead>
                                        <tr className="text-center align-middle">
                                            <th scope="col" width="5%">#</th>
                                            <th scope="col" width="30%">Last Update</th>
                                            <th scope="col" width="33%">Name</th>
                                            <th scope="col" width="7%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newInq == null || newInq.length == 0 ? (
                                            <tr>
                                                <td colSpan="4" className="text-center"><i>No unread Inquiry</i></td>
                                            </tr>
                                        )
                                            : newInq.map((inq, index) => {
                                                return (
                                                    <tr className="text-center align-middle" key={index + 1}>
                                                        <td>{index + 1}</td>
                                                        <td>{convertDate(inq.last_update)}</td>
                                                        <td>{inq.name}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn-sm btn-primary me-1"
                                                                onClick={() => {
                                                                    popupModal("New", inq.id);
                                                                }}
                                                            >
                                                                <BsChatDotsFill />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab eventKey="seen" title={<><b>Solved</b> <Badge className="ms-1" bg="secondary">{seenInq.length}</Badge></>}>
                            <div className="my-TableContainer table-responsive">
                                <table className="table table-striped table-bordered table-hover commonTable">
                                    <thead>
                                        <tr className="text-center align-middle">
                                            <th scope="col" width="5%">#</th>
                                            <th scope="col" width="23%">Last Update</th>
                                            <th scope="col" width="31%">Name</th>
                                            <th scope="col" width="23%">Last Seen</th>
                                            <th scope="col" width="7%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seenInq == null || seenInq.length == 0 ? <tr>
                                            <td colSpan="5" className="text-center"><i>No seen Inquiry</i></td>
                                        </tr> : seenInq.map((inq, index) => {
                                            return (
                                                <tr className="text-center align-middle" key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td>{convertDate(inq.last_update)}</td>
                                                    <td>{inq.name}</td>
                                                    <td>{inq.last_seen == null ? "-" : convertDate(inq.last_seen)}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn-sm btn-primary me-1"
                                                            onClick={() => {
                                                                popupModal("Seen", inq.id);
                                                            }}
                                                        >
                                                            <BsChatDotsFill />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab eventKey="all" title={<><b>All</b> <Badge className="ms-1" bg="primary">{inquiries.length}</Badge></>}>
                            <div className="my-TableContainer table-responsive">
                                <table className="table table-striped table-bordered table-hover commonTable">
                                    <thead>
                                        <tr className="text-center align-middle">
                                            <th scope="col" width="5%">#</th>
                                            <th scope="col" width="30%">Last Update</th>
                                            <th scope="col" width="33%">Name</th>
                                            <th scope="col" width="10%">Flag</th>
                                            <th scope="col" width="7%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inquiries == null || inquiries.length == 0 ?
                                            (<tr>
                                                <td colSpan="5" className="text-center"><i>No Inquiry</i></td>
                                            </tr>) : inquiries.map((inq, index) => {
                                                return (
                                                    <tr className="text-center align-middle" key={index + 1}>
                                                        <td>{index + 1}</td>
                                                        <td>{convertDate(inq.last_update)}</td>
                                                        <td>{inq.name}</td>
                                                        <td>{inq.flag}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn-sm btn-primary me-1"
                                                                onClick={() => {
                                                                    popupModal("All", inq.id);
                                                                }}
                                                            >
                                                                <BsChatDotsFill />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </LoggedInLayout>
    );
}
