import { Modal } from "react-bootstrap";
import "../../css/theme.css";
import { useEffect, useState } from "react";
import $ from "jquery";
import { IoMdSend } from "react-icons/io";
import { AiOutlinePaperClip, AiOutlineFileImage, AiFillFileText } from "react-icons/ai";
import { BsCheckAll, BsCheck, BsFillCircleFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { MdDownload, MdDoneOutline, MdUploadFile } from "react-icons/md";
import { GiStamper } from "react-icons/gi";

import moment from 'moment-timezone'
import { notify } from "../../components/notify";
import ZoomImg from "../../components/zoomImage";
import UploadSingleImage from "../../components/uploadImages/uploadSingleImage";

import sent_1 from '../../assets/sounds/sent-1.mp3'
import received from '../../assets/sounds/received.mp3'

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
  updateDoc,
  setDoc,
  addDoc,
  limit,
  getDoc
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, getStorage } from "firebase/storage";
import { storage, auth, db } from "../../firebase";

export default function ChatRoom(props) {
  const [allProgs, setAllProgs] = useState([]);
  const [history, setHistory] = useState([]);
  const [latestMsg, setLatestMsg] = useState([]);
  const [lastOnline, setLastOnline] = useState("Offline");
  const [flag, setflag] = useState("");
  const [targetImg, setTargetImg] = useState("-1");
  const [unreadMsgNo, setUnreadMsgNo] = useState(0);
  const [firstUnreadMsg, setFirstUnreadMsg] = useState("");

  //single img
  const [imgUploaded, setImgUploaded] = useState("");
  const [clickUpload, setClickUpload] = useState(false);

  const handleUploadedImage = (img) => {
    alert('gi');
  }
  const zoomImg = (url) => {
    setTargetImg(url);
  }

  function handleTA(event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
      send();
    }
  }

  var firstLoad = true;

  function hideAllInput() {
    $("#usrInput").addClass("d-none");
    $("#usrInput-img").addClass("d-none");
    $("#usrInput-file").addClass("d-none");
    $("#cancelUploadBtn").removeClass("d-none");
  }

  function uploadFile(event) {
    if (event.target.files[0] == null) return;

    $("#usrInput-imgSrc").val("");
    $("#usrInput-img").attr("src", "");
    hideAllInput();
    let file = event.target.files[0];

    $("#usrInput-file").removeClass("d-none");
    $("#usrInput-filename").html(file.name);
  }
  function uploadImg(event) {
    if (event.target.files[0] == null) return;
    $("#usrInput-fileSrc").val("");
    $("#usrInput-filename").html("");
    hideAllInput();

    let file = event.target.files[0];
    console.log(URL.createObjectURL(file));
    $("#usrInput-img").attr("src", URL.createObjectURL(file));
    $("#usrInput-img").removeClass("d-none");
  }
  function cancelUpload(type, event) {
    hideAllInput();

    $("#usrInput").removeClass("d-none");
    $("#cancelUploadBtn").addClass("d-none");
    $("#usrInput-fileSrc").val("");
    $("#usrInput-imgSrc").val("");
  }

  function convertDate(inp) {
    //20220215 1315 15
    //20220215 1315
    let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
    let curDate = curTS.substring(0, 8);
    let inpDate = inp.substring(0, 8);
    let curHr = curTS.substring(8, 10);
    let inpHr = inp.substring(8, 10);
    let curMin = curTS.substring(10, 12);
    let inpMin = inp.substring(10, 12);

    if (curDate != inpDate) {
      return moment(inp, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm");
    }
    else {
      if (curHr != inpHr) {
        let a = (curHr - inpHr).toString();
        a += (curMin - inpMin) == 1 ? " hours ago" : " hours ago"
        return a;
      }
      else if (curMin == inpMin) {
        return "Just now";
      }
      else {
        let a = (curMin - inpMin).toString();
        a += (curMin - inpMin) == 1 ? " minute ago" : " minutes ago"
        return a;
      }
    }
  }

  const scrollEvt = (event) => {
    // const chat_h = document.querySelector("#chat-container").scrollTop;
    // const chat_h2 = document.querySelector("#chat-container").clientHeight;
    // const total_h = document.querySelector("#chat-container").scrollHeight;

    // if(chat_h + chat_h2 == total_h){

    // }
    // if(chat_h==0){
    // todo
    //   readMore();
    // }
  };

  const playSound = (action) => {
    var audio = new Audio(received);
    if (action == "sent") {
      var audio = new Audio(sent_1);
    }

    audio.play();
  }

  useEffect(() => {
    let a = latestMsg;
    let localHist2 = history;
    let localHist = [];
    if (localHist2 != null && localHist2.length != 0) {
      localHist = ((localHist2[0].timestamp > localHist2[localHist2.length - 1].timestamp)) ? localHist2.reverse() : localHist2;
    }
    if (a != null && a.length != 0) {
      if (a[a.length - 1].timestamp != history[0].timestamp || a[0].timestamp != history[0].timestamp) {
        a = localHist.concat(a);
        if (latestMsg[0].from != props.uid) {
          if (!latestMsg[0].seen) {
            latestMsg[0].seen = true;
            setUnreadMsgNo(0);
            setHistory(a);
          }
        }
        else {
          if (a.length >= 2 && a[a.length - 1].timestamp == a[a.length - 2].timestamp) {
            localHist[0].seen = true;
            setHistory(localHist);
          } else {
            setHistory([]);
            setHistory(a);
            //(a>2 && (a[0].timestamp > a[a.length-1].timestamp))? setHistory(a.reverse()) : setHistory(a);
          }
          //playSound("sent");
        }
      }
      else {
        //(localHist>2 && (localHist[0].timestamp > localHist[localHist.length-1].timestamp))? setHistory(localHist.reverse()) : setHistory(localHist);
      }
    }
  }, [latestMsg]);

  useEffect(() => {
    const getProgData = async () => {
      const q = query(
        collection(db, `db_uni/${props.uid}/programme`),
        where("courseName", "!=", "placeholder"),
        orderBy("courseName")
      );
      let data = await getDocs(q);
      setAllProgs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    let a = 0
    props.uid == null || props.uid == "" ? a = 1 : getProgData();
  }, [props.uid]);

  useEffect(() => {
    let localTarget = props.targetInfo;
    if (!props.showModal) return;

    const firstLoad_q = query(
      collection(db, `db_chathistory/${localTarget.id}/history`),
      where("timestamp", "!=", "-1"),
      orderBy("timestamp", "desc"),
      limit(10)
    )
    const newMsg_q = query(
      collection(db, `db_chathistory/${localTarget.id}/history`),
      where("timestamp", "!=", "-1"),
      orderBy("timestamp", "desc"),
      limit(1)
    )

    const firstLoadDb = async () => {
      let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
      let data = await getDocs(firstLoad_q);
      let a = [];

      let unreadNo = 0;
      let lastTS = "";
      data.docs.map((doc, index) => {
        if (doc.data().from !== props.uid && !doc.data().seen && doc.data().timestamp <= curTS) {
          updateSeen(doc.data().timestamp, false);
          doc.data().seen = true;
          unreadNo++;
          lastTS = doc.data().timestamp;
        }

        a.push({ ...doc.data(), id: doc.id });
      });

      if (a.length != 10) {
        $(".readMore").addClass('d-none');
      }
      setHistory(a.reverse());
      setFirstUnreadMsg(lastTS);
      setUnreadMsgNo(unreadNo);

      scrollToBottom();
      // console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    const unsub = onSnapshot(newMsg_q, (querySnapshot) => {
      if (firstLoad) {
        firstLoad = false;
        return;
      }
      let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();

      let latestHist = [];
      querySnapshot.forEach((doc) => {
        latestHist.push({ ...doc.data(), id: doc.id });

        if (doc.data().from !== props.uid && !doc.data().seen && doc.data().timestamp <= curTS) {
          updateSeen(doc.data().timestamp, true);

        }
      });
      setLatestMsg(latestHist);

      scrollToBottom();
    });

    const updateLastOnline = async (curTS) => {
      const chatInfoRef = doc(db, `db_chathistory`, props.targetInfo.id);
      const chatInfoDoc = await getDoc(chatInfoRef);
      let partList = chatInfoDoc.data().participant;

      for (var key in partList) {
        if (partList[key].id === props.uid) {
          partList[key].last_online = curTS;
          partList[key].seen = curTS;

          await updateDoc(doc(db, `db_chathistory`, props.targetInfo.id), {
            participant: partList
          });
        }
      }
    }
    const getOpponentData = async () => {
      const chatInfoRef = doc(db, `db_chathistory`, props.targetInfo.id);
      const chatInfoDoc = await getDoc(chatInfoRef);
      let partList = chatInfoDoc.data().participant;
      for (var key in partList) {
        if (partList[key].id !== props.targetInfo.id) {
          let curlastOnline = partList[key].last_online;
          if (curlastOnline != "online") {
            setLastOnline(convertDate(curlastOnline));
          }
          else {
            setLastOnline("online");
          }
        }
      }
    }
    const updateSeen = async (targetTS, isLatest) => {
      await updateDoc(doc(db, `db_chathistory/${props.targetInfo.id}/history`, targetTS), {
        seen: true
      });

      if (isLatest) {
        await updateDoc(doc(db, `db_chathistory/${props.targetInfo.id}/history`, targetTS), {
          seen: true
        });
      }
      //todo - chg opponent msg to seen.
    }

    firstLoadDb();
    getOpponentData();
    let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
    updateLastOnline(curTS);
    return () => {
      let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
      setHistory([]);
      setLatestMsg([]);
      unsub();
      updateLastOnline(curTS);

    }
  }, [props.showModal]);

  const send = async () => {
    let input = $("#usrInput").val();
    let curTS = moment().tz("Asia/Kuala_Lumpur").format("YYYYMMDDHHmmss").toString();
    if (input == null || input == "") {
      return;
    }
    //send
    setUnreadMsgNo(0);
    await updateDoc(doc(db, `db_uni/${props.uid}/inquiry`, props.targetInfo.id), {
      last_update: curTS
    });

    await updateDoc(doc(db, `db_chatref/${props.targetInfo.send_from}/chatroom`, props.targetInfo.id), {
      last_update: curTS
    });

    await setDoc(doc(db, `db_chathistory/${props.targetInfo.id}/history`, curTS), {
      fileType: "",
      from: props.uid,
      message: input,
      seen: false,
      timestamp: curTS,
    });

    $("#usrInput").val("");
    scrollToBottom();
  }

  const updateFlag = async () => {
    if (props.uid == null || props.uid == "") return;
    await updateDoc(doc(db, `db_uni/${props.uid}/inquiry`, props.targetInfo.id), {
      flag: "close"
    });
    setflag("close");
    notify("success", "Inquiry closed successfully!", "Flag Changed");
  }

  const scrollToBottom = () => {
    let chatbox = document.getElementById("chat-container");
    chatbox.scrollTop = chatbox.scrollHeight;

    // const chat_h = document.querySelector("#chat-container").scrollTop;
    // const chat_h2 = document.querySelector("#chat-container").clientHeight;
    // const total_h = document.querySelector("#chat-container").scrollHeight;

    // if(chat_h + chat_h2 >= total_h-20){
    //   //console.log('yes');
    //   //console.log(chat_h + ' + ' + chat_h2 + ' + ' + total_h);
    //   setTimeout(function(){ console.log('triggered');chatbox.scrollTop = chatbox.scrollHeight+50 }, 500);
    // }
    // else{
    //   //console.log('no');
    //   //console.log(chat_h + ' + ' + chat_h2 + ' + ' + total_h);
    //   //todo - show a float at right side.
    // }
  }

  const readMore = async () => {
    let localHistory = history;
    const load_q = query(
      collection(db, `db_chathistory/${props.targetInfo.id}/history`),
      where("timestamp", "<", history[0].timestamp),
      orderBy("timestamp", "desc"),
      limit(10)
    )
    let data = await getDocs(load_q);
    let newHist = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    let a = localHistory.reverse().concat(newHist);
    if (newHist.length != 10) {
      $('.readMore').addClass("d-none");
    }
    setHistory(a.reverse());
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.onShow()}
      dialogClassName="modal-90w-2"
    >
      <Modal.Header closeButton>
        <div>
          <h5 className="modal-title">Chatroom - {props.targetInfo.name} </h5>
          <span className="lastOnline-txt align-middle text-secondary">{lastOnline == "online" ?
            (<span><BsFillCircleFill style={{ fontSize: "0.5em" }} className="text-online" /> Online</span>)
            : (<span><BsFillCircleFill style={{ fontSize: "0.5em" }} className="text-muted" /> Last Online on {lastOnline}</span>)}</span>
        </div></Modal.Header>
      <Modal.Body>
        <ZoomImg src={targetImg} onClose={() => { setTargetImg("-1") }} />
        <form id="chat-container" onScroll={() => scrollEvt(this)} className="chat-container pb-3 w-100">
          <div className="w-100" style={{ textAlign: "center" }}>
            <div id="readMore" className=" btn readMore" onClick={() => readMore()}>Read More</div>
          </div>
          {history.map((curHist, i) => {
            let a = ("");
            if (curHist.from === props.uid) {
              a = (
                <div key={curHist.timestamp}>
                  <div key={curHist.timestamp} className="w-100 mine-FloatLeft">
                    <div className={i == 0 || curHist.from !== history[i - 1].from ? "talk-bubble tb-me tb-me-first mt-3" : "talk-bubble tb-me mt-1"}>
                      <div className="talktext-me d-flex">
                        {curHist.fileType == "" ? (<p>{curHist.message}</p>) :
                          curHist.fileType == "img" ?
                            (
                              <div className="mb-2">
                                <img src={curHist.message} className="textbox-img me-2" onClick={() => zoomImg(imgUploaded)} />
                                <a className="fileDownload-title align-top fileURL" href={curHist.message} ><MdDownload />{curHist.fileName == null ? "Download" : curHist.fileName}</a>
                              </div>
                            )
                            : (
                              <div className="mb-2">
                                <a href={curHist.message} className="fileURL"><MdDownload />{curHist.fileName == null ? "Download File" : curHist.fileName}</a>
                              </div>
                            )}
                        <div className="tb-smallContainer ms-3 align-bottom text-secondary">
                          <span className="tb-time-me">{moment(curHist.timestamp, "YYYYMMDDHHmmss").format("HH:mm")}</span>
                          <span className="seenIcon ms-1">{curHist.seen ? <BsCheckAll className="text-primary" /> : <BsCheck />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            else {
              a = (
                <div key={curHist.timestamp}>
                  {unreadMsgNo > 0 && curHist.timestamp === firstUnreadMsg ? (<div className="w-100" style={{ textAlign: "center" }}>
                    <div id="unreadMsgFloat" className="newUnreadMsg mt-3 mb-1 btn">{unreadMsgNo} Unread Messages</div>
                  </div>) : ("")}

                  <div key={curHist.timestamp} className="w-100 mine-FloatLeft">
                    <div key={curHist.timestamp} className={i == 0 || curHist.from !== history[i - 1].from ? "talk-bubble tb-him tb-him-first mt-3" : "talk-bubble tb-him mt-1"}>
                      <div className="talktext d-flex">
                        {curHist.fileType == "" ? (<p>{curHist.message}</p>) :
                          curHist.fileType == "img" ?
                            (
                              <div className="mb-2">
                                <img src={curHist.message} className="textbox-img me-2" />
                                <a className="fileDownload-title align-top fileURL" href={curHist.message} ><MdDownload />{curHist.fileName == null ? "Download" : curHist.fileName}</a>
                              </div>
                            )
                            : (
                              <div className="mb-2">
                                <a href={curHist.message} className="fileURL"><MdDownload />{curHist.fileName == null ? "Download File" : curHist.fileName}</a>
                              </div>
                            )}
                        <div className="tb-smallContainer ms-3 align-bottom text-secondary">
                          <span className="tb-time">{moment(curHist.timestamp, "YYYYMMDDHHmmss").format("HH:mm")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {curHist.linkType == null ? ("") : curHist.linkType == "uni" ? <span className="fromTxt">Sent from <b>University</b></span> : <span className="fromTxt">Sent from <b>{allProgs.find(x => x.id === curHist.linkID).courseName}</b></span>}
                </div>
              );
            }
            return a;
          })}
        </form>

        {/* Img and file */}
        <input type="file" id="usrInput-fileSrc" onChange={uploadFile}></input>
        <input type="file" id="usrInput-imgSrc" accept="image/*" onChange={uploadImg}></input>

        <div className="attachmentsBox mt-3 border-top mb-1 pt-2 pb-2">
          {/* <span className="btn btn-sm btn-outline-primary" onClick={() => console.log(history)}>test</span> */}
          <label htmlFor="usrInput-fileSrc" className="btn btn-sm btn-outline-primary"><AiOutlinePaperClip /></label>
          <label htmlFor="usrInput-imgSrc" className="ms-1 btn-sm btn btn-outline-primary" onClick={() => setClickUpload(!clickUpload)}><AiOutlineFileImage /></label>

          <button type="button" className="btn btn-primary" onClick={() => console.log(history)}>test</button>

          {props.targetInfo.flag == "open" && flag != "close" ? (<button onClick={() => updateFlag()} type="button" style={{ float: 'right' }} className="text-right btn btn-sm btn-outline-primary ms-2" data-toggle="tooltip" data-placement="top" title="Mark as resolved"><GiStamper className="markAsResolveBtn me-1" />Mark as Solved</button>
          ) :
            (<button onClick={() => updateFlag()} type="button" style={{ float: 'right' }} className="text-right btn btn-sm btn-success ms-2 disabled"><MdDoneOutline className="markAsResolveBtn me-1" />Inquiry Solved</button>
            )
          }
        </div>
        <div className="w-100 d-flex">
          <div className="usrInputContainer border border-muted w-90 d-flex" style={{ width: "95%" }}>
            <img id="usrInput-img" className="d-none usrUploadImg" src="https://cdn4.buysellads.net/uu/1/3386/1525189943-38523.png"></img>
            <div id="usrInput-file" className="d-none usrUploadFile"><AiFillFileText className="fileIcon" /><a href="" id="usrInput-filename">test.pdf</a></div>
            <textarea
              tabIndex={-1}
              onKeyDown={handleTA}
              id="usrInput"
              className="border-0 form-control w-100"
              rows="2"
              style={{ resize: "none", width: "auto", flexGrow: "1" }}
              placeholder="Enter your message..."
            ></textarea>

            <FaTimes className="cancelAttachmentBtn d-none" id="cancelUploadBtn" onClick={() => { cancelUpload() }} />
          </div>
          <div className="ms-2 w-5">
            <b><h3 className="sendBtn" onClick={() => { send() }}><IoMdSend /></h3></b>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
