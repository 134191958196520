import LoggedInLayout from "../../layouts/loggedInLayout";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import Modal from "../../components/modal-container";
import defaultImg from '../../assets/images/upload-placeholder.jpg';

import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  deleteDoc,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, getStorage } from "firebase/storage";
import { storage, auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { notify } from "../../components/notify";
import $ from "jquery";
import { useEffect, useState } from "react";

export default function LivingPage(props) {
  const [user, loading, error] = useAuthState(auth);
  const [livings, setLivings] = useState([]);

  const [curId, setCurId] = useState("");
  const [cur, setCur] = useState({});
  const [action, setAction] = useState("New");
  const [showModal, setShowModal] = useState(false);

  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $(`#imgPreview`).attr("src", e.target.result)
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  async function getDbData(uid) {
    const q = query(collection(db, `db_uni/${uid}/living`));
    let data = await getDocs(q);
    setLivings(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }
  
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        const q = query(
          collection(db, `db_uni/${user.uid}/living`),
          where("created_on", "!=","placeholder"),
          orderBy("created_on")
        );
        const unsub = onSnapshot(q, (querySnapshot) => {
          setLivings([]);
          querySnapshot.forEach((doc) => {
            setLivings((oldArr) => [...oldArr, { ...doc.data(), id: doc.id }]);
          });
        });
  
        const getDbData = async () => {
          window.preloader(true);
          const q = query(
            collection(db, `db_uni/${user.uid}/living`),
            where("created_on", "!=", "placeholder"),
            orderBy("created_on")
          );
          let data = await getDocs(q);
          setLivings(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          window.preloader(false);
        };
        getDbData();
      } 
    });

    return () => {
      setLivings([]);
      //unsub();
    };
  }, []);

  async function UploadImg(id, tAction) {
    window.preloader(true);
    let theFile = document.getElementById("uploadBtnId").files[0];
    console.log(theFile);

    const storageRef = ref(
      storage,
      `HighSchool_Universities/${user.uid}/living/${id}.jpg`
    );
    const uploadTask = uploadBytesResumable(storageRef, theFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => { },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          const updateURL = async () => {
            const tDoc = doc(
              db,
              `db_uni/${user.uid}/living`,
              `LV${id}`
            );
            const updatedField = { cover_url: url };
            await updateDoc(tDoc, updatedField);
          };
          updateURL();

          if(tAction == "1"){
            alert(`New living is added Successfully! Kindly check after 5 mins if change is not visible.`);
            notify("success", "New Living is Created!", "Created Successfully");
          }
          else{
            notify("success", "Living is updated successfully", "Living Updated");
          }

          setShowModal(!showModal);
        });
      }
    );
    window.preloader(false);
  }

  function save() {
    window.preloader(true);
    let title = $("#title").val();
    let title_cn = $("#title_cn").val();
    let content = $("#tcontent").val();
    let content_cn = $("#content_cn").val();
    let publish = $("#publish").prop("checked");

    let uploadedFile = document.getElementById("uploadBtnId").files[0];
    console.log('test: ');
    console.log(uploadedFile);
    let theSrc = $('#imgPreview').prop('src');
    // if (uploadedFile == null && (theSrc.includes("upload-placeholder.65c08d093ed42e5f9611.jpg") || theSrc == null)) {
    //   notify('warning', 'Image is missing. Please upload an image!.', "Image is missing");
    //   window.preloader(false);
    //   return;
    // }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/modifyLiving",
      data: {
        action: action,
        uid: user.uid,
        livingId: curId,
        title: title,
        title_cn: title_cn,
        content: content,
        content_cn: content_cn,
        publish: publish
      },
      success: function (msgInput) {
        let msg = msgInput.split(":")[0];
        let id = msgInput.split(":")[1];

        if (msg != "1" && msg != "2") {
          notify("error", "ID not found. Please contact admin.", "Error");
          window.preloader(false);
          return;
        }  

        if (uploadedFile == null) {
          setShowModal(!showModal);
        }
        else {
          UploadImg(id=="-1"? cur.created_on : id, msg);
        }
        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  const deleteItem = async (tId) => {
    //prompt delete or not.
    const getUIntro = await deleteDoc(
      doc(db, `db_uni/${user.uid}/living`, tId)
    );

    let fullURL = `HighSchool_Universities/${user.uid}/living/${cur.created_on}.jpg`;
    const desertRef = ref(storage, fullURL);
    deleteObject(desertRef).then(() => {
        // File deleted successfully
    }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log('Error on image deletion: ', error);
    });

    notify("success", `"${cur.title}" is Deleted!`, "Deleted Successfully");
    setShowModal(false);
  };

  const popupModal = (id, uid) => {
    setCur({});
    setCurId("");

    if (id == "") {
      setAction("New");
    }
    else if(id=="Delete"){
      setAction("Delete");
      var localCur = livings.find((item) => item.id == uid);
      setCur(localCur);
      setShowModal(true);
    }
    else {
      setAction("Edit");
      setCurId(id);
      getDetail(id);
    }
    setShowModal(true);
  }

  const getDetail = (id) => {
    setCur(livings.find((item) => item.id == id));
  };

  return (
    <LoggedInLayout>
      {/* popup */}
      <Modal
        showModal={showModal}
        onShow={() => {
          setShowModal(!showModal);
        }}
        onSave={() => {
          save(cur.id);
        }}
        onDelete={() => {
          deleteItem(cur.id);
        }}
        itemName={cur.title}
        action={action}
        title="Living"
      >
        <div className="form-group mt-1">
          <label htmlFor="title" className="my-FormLabel">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            defaultValue={cur.title}
            placeholder="Title"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="title_cn" className="my-FormLabel">
            Title (CN)
          </label>
          <input
            type="text"
            className="form-control"
            id="title_cn"
            defaultValue={cur.title_cn}
            placeholder="Title(CN)"
          ></input>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="tcontent" className="my-FormLabel">
            Content
          </label>
          <textarea
            type="text"
            className="form-control"
            id="tcontent"
            defaultValue={cur.content}
            placeholder="Please provide a brief introduction on this living"
            rows="8"
          ></textarea>
        </div>
        <div className="form-group mt-1">
          <label htmlFor="content_cn" className="my-FormLabel">
            Content (cn)
          </label>
          <textarea
            type="text"
            className="form-control"
            id="content_cn"
            defaultValue={cur.content_cn}
            placeholder="介绍与学校有关的生活"
            rows="8"
          ></textarea>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="publish" className="my-FormLabel">
            Publish :
          </label>
          <input
            type="checkbox"
            className="form-checkbox my-CheckBox ms-3"
            id="publish"
            defaultChecked={cur.publish}
          ></input>
        </div>
        <div className="form-group mt-1 w-100">
          <label htmlFor="image" className="my-FormLabel">
            Cover Image
          </label><br />
          <span className="btn btn-default btn-file w-100">
            <input type="file" className="form-control" id="uploadBtnId" accept="image/*" onChange={(event) => {
              readURL(event.target);
            }}></input>
          </span>
        </div>

        <div className="form-group mt-1">
          <div className="text-center"><img id="imgPreview" style={{ width: "40vw", height: "30vw" }} src={cur.cover_url == null || cur.cover_url == "" ? defaultImg : cur.cover_url}></img></div>
        </div>
      </Modal>

      <div className="contentContainer" id="settings-0">
        <div className="contentTitle position-relative">
          <span>Manage Living(s)</span>
        </div>

        <div className="contentBody">
          <div className="mt-3 my-BtnGrp">
            <button
              type="button"
              className="btn btn-primary ms-3 mb-3 text-light"
              id="addLiving"
              onClick={() => {
                popupModal("");
              }}
            >
              <IoIosAddCircle /> Add Living
            </button>
            </div>
          <div className="my-TableContainer table-responsive">
            <table className="table table-striped table-bordered table-hover commonTable">
              <thead>
                <tr className="text-center align-middle">
                  <th scope="col" width="5%">#</th>
                  <th scope="col" width="29%">Title</th>
                  <th scope="col" width="29%">Title(CN)</th>
                  <th scope="col" width="10%">Image</th>
                  <th scope="col" width="10%">Views</th>
                  <th scope="col" width="12%">Status</th>
                  <th scope="col" width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {livings.map((living, index) => {                              
                  return (
                    <tr className="text-center align-middle" key={index+1}>
                      <td>{index+1}</td>
                      <td>{living.title}</td>
                      <td>{living.title_cn}</td>
                      <td><img width="90%" height="50px" src={living.cover_url == null || living.cover_url == ""? defaultImg: living.cover_url}/></td>
                      <td>{living.view_count == null || living.view_count == ""? 0 : living.view_count}</td>
                      <td>{living.publish != null && living.publish? "Active" : "Inactive"}</td>
                      <td>
                        <button
                            type="button"
                            className="btn-sm btn-primary me-1"
                            onClick={() => {
                              popupModal(living.id);
                            }}
                          >
                            <BsFillPencilFill />
                          </button>
                          <button
                            type="button"
                            className="btn-sm btn-danger mt-2"
                            onClick={() => {
                              popupModal('Delete', living.id);
                            }}
                          >
                            <BsTrashFill />
                        </button>
                      </td>
                    </tr>
                  );              
                })}
              </tbody>
            </table>
          </div>  
        </div>
      </div>
    </LoggedInLayout>
  );
}
