import { Modal } from "react-bootstrap";
import "../css/theme.css";

export default function ModalContainer(props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.onShow()}
      dialogClassName={props.action == "Delete" ? "modal-30w" : "modal-90w"}
      style={{ marginTop: "2vw" }}
    >
      <Modal.Header closeButton><h5 className="modal-title">{props.action} {props.title} {props.itemName == null ? "" : `- ` + props.itemName}</h5></Modal.Header>
      <Modal.Body>
        {props.action == "Delete" ?
          <>
            Are you sure to Delete {props.title} <b>"{props.itemName}"</b> ? <br />
            <i>*(Change is Irreversible)</i>
          </>
          :
          props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.action == "Delete" ?
          <>
            <button type="button" className="btn btn-secondary" onClick={() => props.onShow()}>Cancel</button>
            <button type="button" className="btn btn-danger" onClick={() => props.onDelete(props)}>Delete</button>
          </>
          :
          (
            <>
              <button type="button" className="btn btn-secondary" onClick={() => props.onShow()}>Close</button>
              <button type="button" className="btn btn-success" onClick={() => props.onSave(props)}>Save</button>
            </>
          )}
      </Modal.Footer>

    </Modal >
  );
}
