import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/settings.css";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import $ from "jquery";
import { notify } from "../../components/notify";
import { IoIosSave } from "react-icons/io"

export default function SettingsPage(props) {
  const [user, loading, error] = useAuthState(auth);
  let uid = null;

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        uid = user.uid;
        getSettings(user.uid);
        getPersons(user.uid);
      }
    });
  });

  async function getSettings(tuid) {
    const getSetting1 = await getDoc(
      doc(db, `db_uni/${tuid}/settings`, "institution_info")
    );
    if (getSetting1.exists()) {
      const data = getSetting1.data();
      $("#coName").val(data.coName);
      $("#coRegNo").val(data.coRegNo);
      $("#insName").val(data.insName);
      $("#insApproval").val(data.insApproval);
      $("#addrLine1").val(data.addrLine1);
      $("#addrLine2").val(data.addrLine2);
      $("#addrLine3").val(data.addrLine3);
      $("#postcode").val(data.postcode);
      $("#city").val(data.city);
      $("#country").val(data.country);
      $("#state").val(data.state);
      $("#phone1").val(data.phone1);
      $("#phone2").val(data.phone2);
      $("#fax1").val(data.fax1);
      $("#fax2").val(data.fax2);
      $("#websiteUrl").val(data.websiteUrl);
    }
  }

  async function getPersons(tuid) {
    let personName = ["pic", "ca", "ap"];
    for (var i = 0; i < 3; i++) {
      const getSetting2 = await getDoc(
        doc(db, `db_uni/${tuid}/personSettings`, `person_${i}`)
      );

      if (getSetting2.exists) {
        let data = getSetting2.data();
        $(`#${personName[i]}_gender`).val(data.gender);
        $(`#${personName[i]}_jobTitle`).val(data.jobTitle);
        $(`#${personName[i]}_name`).val(data.name);
        $(`#${personName[i]}_phone`).val(data.phone);
        $(`#${personName[i]}_mobile`).val(data.mobile);
        $(`#${personName[i]}_department`).val(data.department);
        $(`#${personName[i]}_email`).val(data.email);
      } else {
        notify(
          "error",
          "Persons not found. Please contact administrator.",
          "Error"
        );
      }
    }
  }

  function save() {
    window.preloader(true);
    let persons = [];
    let personName = ["pic", "ca", "ap"];
    for (var i = 0; i < personName.length; i++) {
      let pic = [
        `person_${i}`,
        $(`#${personName[i]}_gender`).val(),
        $(`#${personName[i]}_name`).val(),
        $(`#${personName[i]}_department`).val(),
        $(`#${personName[i]}_phone`).val(),
        $(`#${personName[i]}_mobile`).val(),
        $(`#${personName[i]}_email`).val(),
        $(`#${personName[i]}_jobTitle`).val(),
      ];
      persons.push(pic);
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateSettings",
      data: {
        uid: uid,
        coName: $("#coName").val(),
        coRegNo: $("#coRegNo").val(),
        insName: $("#insName").val(),
        insApproval: $("#insApproval").val(),
        addrLine1: $("#addrLine1").val(),
        addrLine2: $("#addrLine2").val(),
        addrLine3: $("#addrLine3").val(),
        postcode: $("#postcode").val(),
        city: $("#city").val(),
        country: $("#country").val(),
        state: $("#state").val(),
        phone1: $("#phone1").val(),
        phone2: $("#phone2").val(),
        fax1: $("#fax1").val(),
        fax2: $("#fax2").val(),
        websiteUrl: $("#websiteUrl").val(),
        persons: persons,
      },
      success: function (msg) {
        if (msg === "1") {
          notify(
            "success",
            "Your Settings is updated!",
            "Updated Successfully"
          );
        } else if (msg === "-1") {
          notify(
            "error",
            "Internal Error: Update settings error.<br>Please contact admin.",
            "Update Error"
          );
        } else if (msg === "-2") {
          notify(
            "error",
            "Internal Error: Update persons settings error.<br>Please contact admin.",
            "Update Error"
          );
        } else {
          notify(
            "error",
            "Internal Error: Unknown Error <br>Please contact admin.",
            "Update Error"
          );
        }

        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  return (
    <LoggedInLayout id="settings-container">
      <form className="contentContainer settings-container" id="settings-0">
        <div className="layoutTitle">Institution Profile</div>
        <table className="form-group"><tbody>
          <tr>
            <td>
              <label htmlFor="coName">Company Name</label>
              <input
                type="text"
                className="form-control settings-input "
                id="coName"
                placeholder="Company Name"
              ></input>
            </td>

            <td>
              <label htmlFor="coRegNo">Company Registration No</label>
              <input
                type="text"
                className="form-control settings-input"
                id="coRegNo"
                placeholder="Company Registration No"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="insName">Institution Name</label>
              <input
                type="text"
                className="form-control settings-input"
                id="insName"
                placeholder="Institution Name"
              ></input>
            </td>

            <td>
              <label htmlFor="insApproval">Institution Approval #</label>
              <input
                type="text"
                className="form-control settings-input"
                id="insApproval"
                placeholder="Institution Approval"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control settings-input"
                id="addrLine1"
                placeholder="Address Line 1"
              ></input>
              <input
                type="text"
                className="form-control settings-input"
                id="addrLine2"
                placeholder="Address Line 2"
              ></input>
              <input
                type="text"
                className="form-control settings-input"
                id="addrLine3"
                placeholder="Address Line 3 (Optional)"
              ></input>
            </td>

            <td>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="postcode">Postcode</label>
                      <input
                        type="number"
                        className="form-control settings-input tb"
                        id="postcode"
                        placeholder="Postcode"
                      ></input>
                    </td>
                    <td>
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className="form-control settings-input"
                        id="city"
                        placeholder="City"
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="country">Country</label>
                      <input
                        type="text"
                        className="form-control settings-input tb"
                        id="country"
                        placeholder="Country"
                      ></input>
                    </td>
                    <td>
                      <label htmlFor="state">State (If applicable)</label>
                      <select id="state" className="form-select" defaultValue={''}>
                        <option value="" disabled>State</option>
                        <option value="JHR">Johor 柔佛</option>
                        <option value="KDH">Kedah 吉打</option>
                        <option value="KTN">Kelantan 吉兰丹</option>
                        <option value="MLK">Melaka 马六甲</option>
                        <option value="NSN">Negeri Sembilan 森美兰</option>
                        <option value="PHG">Pahang 彭亨</option>
                        <option value="PNG">Penang 槟城</option>
                        <option value="PRK">Perak 霹雳</option>
                        <option value="PLS">Perlis 玻璃市</option>
                        <option value="SBH">Sabah 沙巴</option>
                        <option value="SWK">Sarawak 砂拉越</option>
                        <option value="SGR">Selangor 雪兰莪</option>
                        <option value="TRG">Terengganu 登嘉楼</option>
                        <option value="KUL">W.P. Kuala Lumpur 吉隆坡</option>
                        <option value="PJY">W.P. Putrajaya 布城</option>
                        <option value="LBN">W.P. Labuan 纳闽</option>
                        <option value="OTR">Others 其它</option>
                      </select>
                    </td>
                  </tr></tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="phone1">Phone #1</label>
              <input
                type="number"
                className="form-control settings-input"
                id="phone1"
                placeholder="Phone #1"
              ></input>
            </td>

            <td>
              <label htmlFor="phone2">Phone #2</label>
              <input
                type="number"
                className="form-control settings-input"
                id="phone2"
                placeholder="Phone #2"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="fax1">Fax #1</label>
              <input
                type="number"
                className="form-control settings-input"
                id="fax1"
                placeholder="Fax #1"
              ></input>
            </td>

            <td>
              <label htmlFor="fax1">Fax #2</label>
              <input
                type="number"
                className="form-control settings-input"
                id="fax2"
                placeholder="Fax #2"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="websiteUrl">Website URL</label>
              <input
                type="text"
                className="form-control settings-input"
                id="websiteUrl"
                placeholder="E.g: https://www.example.com"
              ></input>
            </td>
          </tr></tbody>
        </table>
      </form>

      <form className="settings-container" id="settings-1">
        <div className="layoutTitle">Person In Charge (for administration)</div>
        <table className="form-group"><tbody>
          <tr>
            <td colSpan="2">
              <label htmlFor="pic_name">Full Name</label>
              <div className="nameBox">
                <select id="pic_gender" className="form-select settings-gender">
                  <option value="M">Mr</option>
                  <option value="F">Mrs</option>
                </select>
                <input
                  type="text"
                  className="form-control settings-input"
                  id="pic_name"
                  placeholder="Name of Person In Charge"
                ></input>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="pic_jobTitle">Job Title</label>
              <input
                type="text"
                className="form-control settings-input"
                id="pic_jobTitle"
                placeholder="Job Title"
              ></input>
            </td>

            <td>
              <label htmlFor="pic_department">Department</label>
              <input
                type="text"
                className="form-control settings-input"
                id="pic_department"
                placeholder="Department"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="pic_phone">Phone Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="pic_phone"
                placeholder="Phone Number"
              ></input>
            </td>

            <td>
              <label htmlFor="pic_mobile">Mobile Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="pic_mobile"
                placeholder="Mobile Number"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="pic_email">Email</label>
              <input
                type="text"
                className="form-control settings-input"
                id="pic_email"
                placeholder="Email"
              ></input>
            </td>
          </tr></tbody>
        </table>
      </form>

      <form className="settings-container" id="settings-2">
        <div className="layoutTitle">Courses Advisor (for course counselling)</div>
        <table className="form-group"><tbody>
          <tr>
            <td colSpan="2">
              <label htmlFor="ca_name">Full Name</label>
              <div className="nameBox">
                <select id="ca_gender" className="form-select settings-gender">
                  <option value="M">Mr</option>
                  <option value="F">Mrs</option>
                </select>
                <input
                  type="text"
                  className="form-control settings-input"
                  id="ca_name"
                  placeholder="Name of Courses Advisor"
                ></input>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ca_jobTitle">Job Title</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ca_jobTitle"
                placeholder="Job Title"
              ></input>
            </td>

            <td>
              <label htmlFor="ca_department">Department</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ca_department"
                placeholder="Department"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ca_phone">Phone Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ca_phone"
                placeholder="Phone Number"
              ></input>
            </td>

            <td>
              <label htmlFor="ca_mobile">Mobile Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ca_mobile"
                placeholder="Mobile Number"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ca_email">Email</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ca_email"
                placeholder="Email"
              ></input>
            </td>
          </tr></tbody>
        </table>
      </form>

      <form className="settings-container" id="settings-3">
        <div className="layoutTitle">Authorised Person</div>
        <table className="form-group"><tbody>
          <tr>
            <td colSpan="2">
              <label htmlFor="ap_name">Full Name</label>
              <div className="nameBox">
                <select id="ap_gender" className="form-select settings-gender">
                  <option value="M">Mr</option>
                  <option value="F">Mrs</option>
                </select>
                <input
                  type="text"
                  className="form-control settings-input"
                  id="ap_name"
                  placeholder="Name of Courses Advisor"
                ></input>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ap_jobTitle">Job Title</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ap_jobTitle"
                placeholder="Job Title"
              ></input>
            </td>

            <td>
              <label htmlFor="ap_department">Department</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ap_department"
                placeholder="Department"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ap_phone">Phone Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ap_phone"
                placeholder="Phone Number"
              ></input>
            </td>

            <td>
              <label htmlFor="ap_mobile">Mobile Number</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ap_mobile"
                placeholder="Mobile Number"
              ></input>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="ap_email">Email</label>
              <input
                type="text"
                className="form-control settings-input"
                id="ap_email"
                placeholder="Email"
              ></input>
            </td>
          </tr></tbody>
        </table>
      </form>

      <div className="mt-3 my-BtnGrp">
        <button
          className="btn btn-success"
          onClick={() => {
            save();
          }}
        >
          <IoIosSave />
          Save
        </button>
      </div>
    </LoggedInLayout>
  );
}
