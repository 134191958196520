import { Modal } from "react-bootstrap";
import $, { data, extend } from "jquery";
import { db } from "./../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function ForgetPassword(props) {
    const API = "http://localhost:4220/highschool-web/asia-southeast1/sendPasswordResetEmail";
    let allow = true;
    let timer = 60;

    async function getUsers() {
        if (allow) {
            const mailto = $('#reset-email').val();
            const userCollRef = query(
                collection(db, "db_uni"),
                where("terminate", "==", false),
                where("email", "==", mailto)
            );

            const querySnapshot = await getDocs(userCollRef);

            if (!querySnapshot.empty) {
                querySnapshot.forEach((userData) => {
                    let uid = userData.id;
                    proceedReset(uid);
                });
            } else {
                displayRAlert("Invalid email");
            }
        } else {
            displayRAlert("Reset password email has been sent to your email. Kindly check your spam/junk mailbox if unable to find the email in inbox.<br>Follow the instructions provided in the email to reset the password.<br><br>若没收到邮件，请于一分钟后再尝试！");
        }
    }

    function proceedReset(uid) {
        //TODO ADD PRELOADER
        $.ajax({
            type: 'POST',
            url: API,
            data: { "uid": uid, "type": "uni" },
            success: function (msg) {
                if (msg.indexOf('success') > -1) {
                    allow = false;
                    spamPrevention();
                    displayRAlert("Reset password email has been sent to your email. Kindly check your spam/junk mailbox if unable to find the email in inbox.<br>Follow the instructions provided in the email to reset the password.");
                } else if (msg.indexOf('error') > -1) {
                    displayRAlert("503 Internal Server Error. Please try again or contact IT Support.");
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                displayRAlert("500 Internal Server Error");
            }
        });
    }

    function displayRAlert(args) {
        let Rmsg = document.getElementById("reset-msg");
        Rmsg.innerHTML = args;
        $("#reset-msg").css("display", "block");
    }

    function spamPrevention() {
        const sentBtn = document.getElementById("send"),
            txt = document.getElementById("send-txt"),
            counter = document.getElementById("timer");
        txt.innerHTML = "Re-Send";
        $("#send").removeClass("btn-primary").addClass("btn-secondary deny");
        let interval = setInterval(function () {
            counter.innerHTML = `(${timer})`;
            if (--timer < 0) {
                clearInterval(interval);
                sentBtn.innerHTML = '<span id="send-txt">Send</span><span id="timer"></span>';
                $("#send").removeClass("btn-secondary deny").addClass("btn-primary");
                allow = true;
                timer = 60;
            }
        }, 1000);
    }

    return (
        <Modal
            show={props.showModal}
            onHide={() => props.onShow()}
            style={{ marginTop: "12vw" }}
        >

            <Modal.Header closeButton><h5 className="modal-title">Reset Password</h5></Modal.Header>
            <Modal.Body>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: '500', lineHeight: '1.5vw', paddingRight: '1vw' }}><span style={{ fontSize: '1.10rem' }}>Email:</span></td>
                            <td>
                                <input type="text" className="form-control" id="reset-email" />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <p id="reset-msg"></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => props.onShow()}>Close</button>
                <button type="button" className="btn btn-primary" id="send" onClick={() => getUsers()}><span id="send-txt">Send</span><span id="timer"></span></button>
            </Modal.Footer>

        </Modal>
    );
}