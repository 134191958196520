import LoggedInLayout from "../../layouts/loggedInLayout";
import "../../css/pages/settings.css";
import { useEffect, useState } from "react";
import { doc, getDoc, collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import $ from "jquery";
import { notify } from "../../components/notify";
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { sendSignInLinkToEmail } from "firebase/auth";

export default function SettingsPage(props) {
  const [usernameField, setUsernameField] = useState("");
  const [username, setUsername] = useState("");
  const [UrlField, setUrlField] = useState("");
  const [url, setUrl] = useState("");
  const [password, setPassword] = useState("");
  const [passwordENC, setPasswordENC] = useState("");
  const [user] = useAuthState(auth);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0);
        getLoginInfo(user.uid);
      }
    })

    return () => {
    }
  }, [user, username, password, passwordENC]);

  function chgUsername(action) {
    $("#username").prop("disabled", !action);
    if (action) {
      chgPw(false);
      chgUrl(false);
      $("#chgNameBtn").addClass("d-none");
      $("#chgNameBtn1").removeClass("d-none");
      $("#nameSubmitBtn").removeClass("d-none");
    } else {
      setUsernameField(username);
      $("#chgNameBtn").removeClass("d-none");
      $("#chgNameBtn1").addClass("d-none");
      $("#nameSubmitBtn").addClass("d-none");
      $("#uname-alert").css("display", "none");
    }
  }
  function chgUrl(action) {
    $("#uni_url").prop("disabled", !action);
    if (action) {
      chgPw(false);
      chgUsername(false);
      $("#chgUrlBtn").addClass("d-none");
      $("#chgUrlBtn1").removeClass("d-none");
      $("#urlSubmitBtn").removeClass("d-none");
    } else {
      setUrlField(url);
      $("#chgUrlBtn").removeClass("d-none");
      $("#chgUrlBtn1").addClass("d-none");
      $("#urlSubmitBtn").addClass("d-none");
      $("#url-alert").css("display", "none");
    }
  }
  function chgPw(action) {
    if (!action) {
      $("#old_password").val(password);
      $("#chgPwBtn").removeClass("d-none");
      $("#chgPwBtn1").addClass("d-none");
      $("#pwSubmitBtn").addClass("d-none");
      $("#pwFields").addClass("d-none");
      $("#old_password").prop("disabled", true);
    } else {
      chgUsername(false);
      chgUrl(false);
      $("#old_password").val("");
      $("#password").val("");
      $("#password2").val("");
      $("#chgPwBtn").addClass("d-none");
      $("#chgPwBtn1").removeClass("d-none");
      $("#pwSubmitBtn").removeClass("d-none");
      $("#pwFields").removeClass("d-none");
      $("#old_password").prop("disabled", false);
    }
  }

  function submitPw() {
    window.preloader(true);

    const oldPw = $("#old_password").val();
    const pw = $("#password").val();
    const pw2 = $("#password2").val();

    if (oldPw === "") {
      notify("warning", "Old password cannot be empty!", "Empty Old Password");
      window.preloader(false);
      return;
    } else if (pw.length < 6) {
      notify("warning", "Password must be at least 6 characters long", "Password Length");
      window.preloader(false);
      return;
    } else if (pw === "" || pw2 === "") {
      notify("warning", "Please fill all field!", "No Empty Field");
      window.preloader(false);
      return;
    } else if (pw !== pw2) {
      notify("warning", "Repeated new password does not match. Please try again.", "Repeat Password Error");
      window.preloader(false);
      return;
    } else if (pw === CryptoJS.AES.decrypt(passwordENC, "unipw").toString(CryptoJS.enc.Utf8)) {
      chgPw(false);
      window.preloader(false);
      return;
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateLoginSetting",
      data: {
        uid: user.uid,
        action: "pw",
        username: "",
        password: pw,
        old_password: oldPw,
      },
      success: function (msg) {
        if (msg === "1") {
          notify("success", "Your Password is changed successfully!", "Updated Successfully");
          maskPassword(pw);
          chgPw(false);
        } else if (msg === "-1") {
          notify("error", "Internal Error: Account not found. <br>Please contact admin.", "Update Error");
        } else if (msg === "-2") {
          notify("warning", "Incorrect old password. Please try again!", "Incorrect Old Password");
        } else {
          notify("error", "Internal Error: Unknown Error <br>Please contact admin.", "Update Error");
        }

        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }
  function submitUsername() {
    window.preloader(true);
    const usernameInput = $("#username").val();

    if (usernameInput === "") {
      notify("warning", "New username cannot be empty!", "Empty New Username");
      window.preloader(false);
      return;
    } else if (usernameInput === username.toUpperCase()) {
      window.preloader(false);
      chgUsername(false);
      setUsername(username.toUpperCase());
      return;
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateLoginSetting",
      data: {
        uid: user.uid,
        action: "username",
        username: usernameInput.toLowerCase(),
        password: "",
        old_password: "",
      },
      success: function (msg) {
        if (msg === "2") {
          notify("success", "Your Username is changed successfully!", "Updated Successfully");
          chgUsername(false);
          setUsername(usernameInput);
        } else if (msg === "-1") {
          notify(
            "error",
            "Internal Error: Account not found. <br>Please contact admin.",
            "Update Error"
          );
        } else {
          notify(
            "error",
            "Internal Error: Unknown Error <br>Please contact admin.",
            "Update Error"
          );
        }

        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }
  function submitUrl() {
    window.preloader(true);
    const urlInput = $("#uni_url").val();

    if (urlInput === "") {
      notify("warning", "URL cannot be empty!", "Empty New URL");
      window.preloader(false);
      return;
    } else if (urlInput === url.toLowerCase()) {
      window.preloader(false);
      chgUrl(false);
      setUrl(url.toLowerCase());
      return;
    }

    $.ajax({
      type: "POST",
      url: "http://localhost:4220/highschool-web/asia-southeast1/updateLoginSetting",
      data: {
        uid: user.uid,
        action: "url",
        uni_url: urlInput,
        username: "",
        password: "",
        old_password: "",
      },
      success: function (msg) {
        if (msg === "3") {
          notify("success", "Your URL is changed successfully!", "Updated Successfully");
          chgUrl(false);
          setUrl(urlInput);
          setUrlField(urlInput);
        } else if (msg === "-1") {
          notify(
            "error",
            "Internal Error: Account not found. <br>Please contact admin.",
            "Update Error"
          );
        } else {
          notify(
            "error",
            "Internal Error: Unknown Error <br>Please contact admin.",
            "Update Error"
          );
        }

        window.preloader(false);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.preloader(false);
      },
    });
  }

  async function getLoginInfo(tuid) {
    const getSetting = await getDoc(doc(db, `db_uni`, tuid));
    const getSetting2 = await getDoc(
      doc(db, `db_uni/${tuid}/settings`, "account_info")
    );
    if (getSetting.exists() && getSetting2.exists()) {
      const data = getSetting.data();
      const data2 = getSetting2.data();
      let ll_timestamp = moment(data2.last_login, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm");

      setUsernameField((data.username).toUpperCase());
      setUsername((data.username).toUpperCase());
      setPasswordENC(data2.password);
      setUrl((data.uni_url).toLowerCase());
      setUrlField((data.uni_url).toLowerCase());
      $("#lastLogin").val(ll_timestamp);
      $("#email").val(data.email);
      const dec_password = CryptoJS.AES.decrypt(data2.password, "unipw").toString(CryptoJS.enc.Utf8);

      maskPassword(dec_password);

      $("#old_password").val(password);
    }
  }

  function maskPassword(args) {
    let masked = "";
    for (var i = 0; i < args.length; i++) {
      masked += ".";
    }
    setPassword(masked);
  }

  async function checkUrlAvailability(evt) {
    setUrlField(evt.target.value.toLowerCase());
    const value = evt.target.value.toLowerCase().trim();

    if (value === "") {
      $("#url-alert").text("URL must not be empty.");
      $("#url-alert").css("color", "red");
      $("#url-alert").css("display", "block");
      $("#urlSubmitBtn").prop("disabled", true);
    } else if (!value.match(/^[a-z0-9]+$/i)) {
      $("#url-alert").text("URL can only contains alphanumeric.");
      $("#url-alert").css("color", "red");
      $("#url-alert").css("display", "block");
      $("#urlSubmitBtn").prop("disabled", true);
    } else if (value.length < 3) {
      $("#url-alert").text("URL must at least 2 characters long.");
      $("#url-alert").css("color", "red");
      $("#url-alert").css("display", "block");
      $("#urlSubmitBtn").prop("disabled", true);
    } else {
      let dup = false;
      const qs = await getDocs(query(collection(db, "db_uni"), where("uni_url", "==", value)));
      qs.forEach((doc) => {
        if (doc.id !== user.uid) {
          dup = true;
        }
      });
      if (dup) {
        $("#url-alert").text("URL has been taken.");
        $("#url-alert").css("color", "red");
        $("#url-alert").css("display", "block");
        $("#urlSubmitBtn").prop("disabled", true);
      } else {
        $("#url-alert").text("URL available.");
        $("#url-alert").css("color", "green");
        $("#url-alert").css("display", "block");
        $("#urlSubmitBtn").prop("disabled", false);
      }
    }
  };

    async function checkUsernameAvailability(evt) {
    setUsernameField(evt.target.value.toUpperCase());
    const value = evt.target.value.toLowerCase().trim();
    if (value === "") {
      $("#uname-alert").text("Username must not be empty.");
      $("#uname-alert").css("color", "red");
      $("#uname-alert").css("display", "block");
      $("#nameSubmitBtn").prop("disabled", true);
    } else if (!value.match(/^[a-z0-9]+$/i)) {
      $("#uname-alert").text("Username can only contains alphanumeric.");
      $("#uname-alert").css("color", "red");
      $("#uname-alert").css("display", "block");
      $("#nameSubmitBtn").prop("disabled", true);
    } else if (value.length < 5) {
      $("#uname-alert").text("Username must at least 5 characters long.");
      $("#uname-alert").css("color", "red");
      $("#uname-alert").css("display", "block");
      $("#nameSubmitBtn").prop("disabled", true);
    } else {
      let dup = false;
      const qs = await getDocs(query(collection(db, "db_uni"), where("username", "==", value)));
      qs.forEach((doc) => {
        if (doc.id !== user.uid) {
          dup = true;
        }
      });
      if (dup) {
        $("#uname-alert").text("Username has been taken.");
        $("#uname-alert").css("color", "red");
        $("#uname-alert").css("display", "block");
        $("#nameSubmitBtn").prop("disabled", true);
      } else {
        $("#uname-alert").text("Username available.");
        $("#uname-alert").css("color", "green");
        $("#uname-alert").css("display", "block");
        $("#nameSubmitBtn").prop("disabled", false);
      }
    }
  };

  return (
    <LoggedInLayout id="settings-container">
      <form className="contentContainer settings-container" id="settings-0">
        <div className="layoutTitle">Account Setting</div>
        <div className="form-group">
          <label htmlFor="username">Last Online Session</label>
          <input type="text" id="lastLogin" className="form-control settings-input border-0 bg-white" disabled ></input>
        </div>
        <div className="form-group">
          <label htmlFor="email">Login Email</label>
          <input type="text" className="form-control settings-input" id="email" placeholder="Email for Sign-in" disabled ></input>
        </div>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <div className="d-flex">
            <div className="loginSetting-username-input-container">
              <input type="text" onChange={checkUsernameAvailability} value={usernameField} className="form-control settings-input" id="username" placeholder="Username for Sign-in" disabled ></input>
              <span className="loginSetting-username-msg" id="uname-alert"></span></div>
            <button id="chgNameBtn" type="button" className="btn btn-primary ms-2" onClick={() => chgUsername(true)} >Change</button>
            <button
              id="nameSubmitBtn"
              type="button"
              className="btn btn-success ms-2 d-none"
              onClick={() => submitUsername()}
            >
              Submit
            </button>
            <button
              id="chgNameBtn1"
              type="button"
              className="btn btn-danger ms-2 d-none"
              onClick={() => chgUsername(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="old_password">Current Password</label>
          <div className="d-flex">
            <input
              type="password"
              className="form-control settings-input"
              id="old_password"
              placeholder="Current Password"
              disabled
            ></input>
            <button
              id="pwSubmitBtn"
              type="button"
              className="btn btn-success ms-2 d-none"
              onClick={() => submitPw()}
            >
              Submit
            </button>
            <button
              id="chgPwBtn"
              type="button"
              className="btn btn-primary ms-2"
              onClick={() => chgPw(true)}
            >
              Change
            </button>
            <button
              id="chgPwBtn1"
              type="button"
              className="btn btn-danger ms-2 d-none"
              onClick={() => chgPw(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <div id="pwFields" className="d-none">
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              className="form-control settings-input"
              id="password"
              placeholder="New Password"
            ></input>
          </div>

          <div className="form-group">
            <label htmlFor="password2">Repeat New Password</label>
            <input
              type="password"
              className="form-control settings-input"
              id="password2"
              placeholder="Reenter the password"
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="uni_url">Univerity URL</label>
          <div className="d-flex">
            <div className="loginSetting-username-input-container input-group">
            <div className="input-group-prepend">
              <i><span className="input-group-text">https://highschool.my/undergraduate/</span></i>
            </div>
              <input type="text" onChange={checkUrlAvailability} value={UrlField} className="form-control settings-input" id="uni_url" placeholder="URL redirect to Univeristy Page" disabled ></input>
            </div>
            <button id="chgUrlBtn" type="button" className="btn btn-primary ms-2" onClick={() => chgUrl(true)} >Change</button>
            <button
              id="urlSubmitBtn"
              type="button"
              className="btn btn-success ms-2 d-none"
              onClick={() => submitUrl()}
            >
              Submit
            </button>
            <button
              id="chgUrlBtn1"
              type="button"
              className="btn btn-danger ms-2 d-none"
              onClick={() => chgUrl(false)}
            >
              Cancel
            </button>
          </div>
          <span className="loginSetting-username-msg" id="url-alert"></span>
        </div>
      </form>
    </LoggedInLayout>
  );
}
